import { useQueryClient } from '@tanstack/vue-query';
import { fetchCurrentUser } from '~/features/auth';
import { fetchCycles } from '~/features/cycles';

export default defineNuxtPlugin({
  name: 'prefetch-data',
  dependsOn: ['vue-query'],
  async setup() {
    const queryClient = useQueryClient();

    // Prefetch queries
    await Promise.all([
      queryClient.prefetchQuery({
        queryKey: ['me'],
        queryFn: () => fetchCurrentUser(),
      }),
      queryClient.prefetchQuery({
        queryKey: ['cycles'],
        queryFn: () => fetchCycles(),
      }),
    ]);
  },
});
