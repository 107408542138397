var pr = Object.defineProperty;
var _r = (t, e, r) => e in t ? pr(t, e, { enumerable: !0, configurable: !0, writable: !0, value: r }) : t[e] = r;
var le = (t, e, r) => (_r(t, typeof e != "symbol" ? e + "" : e, r), r);
var mr = /* @__PURE__ */ ((t) => (t.Full_1M = "tn-brand-full-plan-1m", t.Full_3M = "tn-brand-full-plan-3m", t.Basic_1M = "tn-brand-basic-plan-1m", t))(mr || {});
const Es = [
  "tn-brand-full-plan-1m",
  "tn-brand-full-plan-3m"
  /* Full_3M */
], Is = [
  "tn-brand-basic-plan-1m"
  /* Basic_1M */
];
var gr = /* @__PURE__ */ ((t) => (t.Archived = "archived", t.Pending = "pending", t.Draft = "draft", t.Live = "live", t.Approved = "approved", t))(gr || {}), Be = /* @__PURE__ */ ((t) => (t.Unfulfilled = "unfulfilled", t.Fulfilled = "fulfilled", t))(Be || {});
const ys = ["password", "magic-link", "cm-sso"], vs = (t) => new Promise((e) => setTimeout(e, t)), J = (t) => {
  const e = t.data();
  return Object.defineProperty(e, "id", { value: t.id }), e;
}, bs = 2, Ss = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" }
];
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Nt = function(t) {
  const e = [];
  let r = 0;
  for (let n = 0; n < t.length; n++) {
    let i = t.charCodeAt(n);
    i < 128 ? e[r++] = i : i < 2048 ? (e[r++] = i >> 6 | 192, e[r++] = i & 63 | 128) : (i & 64512) === 55296 && n + 1 < t.length && (t.charCodeAt(n + 1) & 64512) === 56320 ? (i = 65536 + ((i & 1023) << 10) + (t.charCodeAt(++n) & 1023), e[r++] = i >> 18 | 240, e[r++] = i >> 12 & 63 | 128, e[r++] = i >> 6 & 63 | 128, e[r++] = i & 63 | 128) : (e[r++] = i >> 12 | 224, e[r++] = i >> 6 & 63 | 128, e[r++] = i & 63 | 128);
  }
  return e;
}, Er = function(t) {
  const e = [];
  let r = 0, n = 0;
  for (; r < t.length; ) {
    const i = t[r++];
    if (i < 128)
      e[n++] = String.fromCharCode(i);
    else if (i > 191 && i < 224) {
      const s = t[r++];
      e[n++] = String.fromCharCode((i & 31) << 6 | s & 63);
    } else if (i > 239 && i < 365) {
      const s = t[r++], a = t[r++], c = t[r++], o = ((i & 7) << 18 | (s & 63) << 12 | (a & 63) << 6 | c & 63) - 65536;
      e[n++] = String.fromCharCode(55296 + (o >> 10)), e[n++] = String.fromCharCode(56320 + (o & 1023));
    } else {
      const s = t[r++], a = t[r++];
      e[n++] = String.fromCharCode((i & 15) << 12 | (s & 63) << 6 | a & 63);
    }
  }
  return e.join("");
}, Ot = {
  /**
   * Maps bytes to characters.
   */
  byteToCharMap_: null,
  /**
   * Maps characters to bytes.
   */
  charToByteMap_: null,
  /**
   * Maps bytes to websafe characters.
   * @private
   */
  byteToCharMapWebSafe_: null,
  /**
   * Maps websafe characters to bytes.
   * @private
   */
  charToByteMapWebSafe_: null,
  /**
   * Our default alphabet, shared between
   * ENCODED_VALS and ENCODED_VALS_WEBSAFE
   */
  ENCODED_VALS_BASE: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
  /**
   * Our default alphabet. Value 64 (=) is special; it means "nothing."
   */
  get ENCODED_VALS() {
    return this.ENCODED_VALS_BASE + "+/=";
  },
  /**
   * Our websafe alphabet.
   */
  get ENCODED_VALS_WEBSAFE() {
    return this.ENCODED_VALS_BASE + "-_.";
  },
  /**
   * Whether this browser supports the atob and btoa functions. This extension
   * started at Mozilla but is now implemented by many browsers. We use the
   * ASSUME_* variables to avoid pulling in the full useragent detection library
   * but still allowing the standard per-browser compilations.
   *
   */
  HAS_NATIVE_SUPPORT: typeof atob == "function",
  /**
   * Base64-encode an array of bytes.
   *
   * @param input An array of bytes (numbers with
   *     value in [0, 255]) to encode.
   * @param webSafe Boolean indicating we should use the
   *     alternative alphabet.
   * @return The base64 encoded string.
   */
  encodeByteArray(t, e) {
    if (!Array.isArray(t))
      throw Error("encodeByteArray takes an array as a parameter");
    this.init_();
    const r = e ? this.byteToCharMapWebSafe_ : this.byteToCharMap_, n = [];
    for (let i = 0; i < t.length; i += 3) {
      const s = t[i], a = i + 1 < t.length, c = a ? t[i + 1] : 0, o = i + 2 < t.length, u = o ? t[i + 2] : 0, _ = s >> 2, f = (s & 3) << 4 | c >> 4;
      let A = (c & 15) << 2 | u >> 6, I = u & 63;
      o || (I = 64, a || (A = 64)), n.push(r[_], r[f], r[A], r[I]);
    }
    return n.join("");
  },
  /**
   * Base64-encode a string.
   *
   * @param input A string to encode.
   * @param webSafe If true, we should use the
   *     alternative alphabet.
   * @return The base64 encoded string.
   */
  encodeString(t, e) {
    return this.HAS_NATIVE_SUPPORT && !e ? btoa(t) : this.encodeByteArray(Nt(t), e);
  },
  /**
   * Base64-decode a string.
   *
   * @param input to decode.
   * @param webSafe True if we should use the
   *     alternative alphabet.
   * @return string representing the decoded value.
   */
  decodeString(t, e) {
    return this.HAS_NATIVE_SUPPORT && !e ? atob(t) : Er(this.decodeStringToByteArray(t, e));
  },
  /**
   * Base64-decode a string.
   *
   * In base-64 decoding, groups of four characters are converted into three
   * bytes.  If the encoder did not apply padding, the input length may not
   * be a multiple of 4.
   *
   * In this case, the last group will have fewer than 4 characters, and
   * padding will be inferred.  If the group has one or two characters, it decodes
   * to one byte.  If the group has three characters, it decodes to two bytes.
   *
   * @param input Input to decode.
   * @param webSafe True if we should use the web-safe alphabet.
   * @return bytes representing the decoded value.
   */
  decodeStringToByteArray(t, e) {
    this.init_();
    const r = e ? this.charToByteMapWebSafe_ : this.charToByteMap_, n = [];
    for (let i = 0; i < t.length; ) {
      const s = r[t.charAt(i++)], c = i < t.length ? r[t.charAt(i)] : 0;
      ++i;
      const u = i < t.length ? r[t.charAt(i)] : 64;
      ++i;
      const f = i < t.length ? r[t.charAt(i)] : 64;
      if (++i, s == null || c == null || u == null || f == null)
        throw new Ir();
      const A = s << 2 | c >> 4;
      if (n.push(A), u !== 64) {
        const I = c << 4 & 240 | u >> 2;
        if (n.push(I), f !== 64) {
          const T = u << 6 & 192 | f;
          n.push(T);
        }
      }
    }
    return n;
  },
  /**
   * Lazy static initialization function. Called before
   * accessing any of the static map variables.
   * @private
   */
  init_() {
    if (!this.byteToCharMap_) {
      this.byteToCharMap_ = {}, this.charToByteMap_ = {}, this.byteToCharMapWebSafe_ = {}, this.charToByteMapWebSafe_ = {};
      for (let t = 0; t < this.ENCODED_VALS.length; t++)
        this.byteToCharMap_[t] = this.ENCODED_VALS.charAt(t), this.charToByteMap_[this.byteToCharMap_[t]] = t, this.byteToCharMapWebSafe_[t] = this.ENCODED_VALS_WEBSAFE.charAt(t), this.charToByteMapWebSafe_[this.byteToCharMapWebSafe_[t]] = t, t >= this.ENCODED_VALS_BASE.length && (this.charToByteMap_[this.ENCODED_VALS_WEBSAFE.charAt(t)] = t, this.charToByteMapWebSafe_[this.ENCODED_VALS.charAt(t)] = t);
    }
  }
};
class Ir extends Error {
  constructor() {
    super(...arguments), this.name = "DecodeBase64StringError";
  }
}
const yr = function(t) {
  const e = Nt(t);
  return Ot.encodeByteArray(e, !0);
}, Ct = function(t) {
  return yr(t).replace(/\./g, "");
}, Dt = function(t) {
  try {
    return Ot.decodeString(t, !0);
  } catch (e) {
    console.error("base64Decode failed: ", e);
  }
  return null;
};
/**
 * @license
 * Copyright 2022 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function vr() {
  if (typeof self < "u")
    return self;
  if (typeof window < "u")
    return window;
  if (typeof global < "u")
    return global;
  throw new Error("Unable to locate global object.");
}
/**
 * @license
 * Copyright 2022 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const br = () => vr().__FIREBASE_DEFAULTS__, Sr = () => {
  if (typeof process > "u" || typeof process.env > "u")
    return;
  const t = process.env.__FIREBASE_DEFAULTS__;
  if (t)
    return JSON.parse(t);
}, Ar = () => {
  if (typeof document > "u")
    return;
  let t;
  try {
    t = document.cookie.match(/__FIREBASE_DEFAULTS__=([^;]+)/);
  } catch {
    return;
  }
  const e = t && Dt(t[1]);
  return e && JSON.parse(e);
}, Tr = () => {
  try {
    return br() || Sr() || Ar();
  } catch (t) {
    console.info(`Unable to get __FIREBASE_DEFAULTS__ due to: ${t}`);
    return;
  }
}, wr = (t) => {
  var e;
  return (e = Tr()) === null || e === void 0 ? void 0 : e[`_${t}`];
};
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function F() {
  return typeof navigator < "u" && typeof navigator.userAgent == "string" ? navigator.userAgent : "";
}
function Nr() {
  return typeof window < "u" && // @ts-ignore Setting up an broadly applicable index signature for Window
  // just to deal with this case would probably be a bad idea.
  !!(window.cordova || window.phonegap || window.PhoneGap) && /ios|iphone|ipod|ipad|android|blackberry|iemobile/i.test(F());
}
function Or() {
  const t = typeof chrome == "object" ? chrome.runtime : typeof browser == "object" ? browser.runtime : void 0;
  return typeof t == "object" && t.id !== void 0;
}
function Cr() {
  return typeof navigator == "object" && navigator.product === "ReactNative";
}
function Dr() {
  try {
    return typeof indexedDB == "object";
  } catch {
    return !1;
  }
}
function Rr() {
  return new Promise((t, e) => {
    try {
      let r = !0;
      const n = "validate-browser-context-for-indexeddb-analytics-module", i = self.indexedDB.open(n);
      i.onsuccess = () => {
        i.result.close(), r || self.indexedDB.deleteDatabase(n), t(!0);
      }, i.onupgradeneeded = () => {
        r = !1;
      }, i.onerror = () => {
        var s;
        e(((s = i.error) === null || s === void 0 ? void 0 : s.message) || "");
      };
    } catch (r) {
      e(r);
    }
  });
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Pr = "FirebaseError";
class re extends Error {
  constructor(e, r, n) {
    super(r), this.code = e, this.customData = n, this.name = Pr, Object.setPrototypeOf(this, re.prototype), Error.captureStackTrace && Error.captureStackTrace(this, Ee.prototype.create);
  }
}
class Ee {
  constructor(e, r, n) {
    this.service = e, this.serviceName = r, this.errors = n;
  }
  create(e, ...r) {
    const n = r[0] || {}, i = `${this.service}/${e}`, s = this.errors[e], a = s ? Lr(s, n) : "Error", c = `${this.serviceName}: ${a} (${i}).`;
    return new re(i, c, n);
  }
}
function Lr(t, e) {
  return t.replace(Mr, (r, n) => {
    const i = e[n];
    return i != null ? String(i) : `<${n}?>`;
  });
}
const Mr = /\{\$([^}]+)}/g;
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Rt(t) {
  const e = [];
  for (const [r, n] of Object.entries(t))
    Array.isArray(n) ? n.forEach((i) => {
      e.push(encodeURIComponent(r) + "=" + encodeURIComponent(i));
    }) : e.push(encodeURIComponent(r) + "=" + encodeURIComponent(n));
  return e.length ? "&" + e.join("&") : "";
}
function kr(t, e) {
  const r = new Ur(t, e);
  return r.subscribe.bind(r);
}
class Ur {
  /**
   * @param executor Function which can make calls to a single Observer
   *     as a proxy.
   * @param onNoObservers Callback when count of Observers goes to zero.
   */
  constructor(e, r) {
    this.observers = [], this.unsubscribes = [], this.observerCount = 0, this.task = Promise.resolve(), this.finalized = !1, this.onNoObservers = r, this.task.then(() => {
      e(this);
    }).catch((n) => {
      this.error(n);
    });
  }
  next(e) {
    this.forEachObserver((r) => {
      r.next(e);
    });
  }
  error(e) {
    this.forEachObserver((r) => {
      r.error(e);
    }), this.close(e);
  }
  complete() {
    this.forEachObserver((e) => {
      e.complete();
    }), this.close();
  }
  /**
   * Subscribe function that can be used to add an Observer to the fan-out list.
   *
   * - We require that no event is sent to a subscriber sychronously to their
   *   call to subscribe().
   */
  subscribe(e, r, n) {
    let i;
    if (e === void 0 && r === void 0 && n === void 0)
      throw new Error("Missing Observer.");
    $r(e, [
      "next",
      "error",
      "complete"
    ]) ? i = e : i = {
      next: e,
      error: r,
      complete: n
    }, i.next === void 0 && (i.next = Le), i.error === void 0 && (i.error = Le), i.complete === void 0 && (i.complete = Le);
    const s = this.unsubscribeOne.bind(this, this.observers.length);
    return this.finalized && this.task.then(() => {
      try {
        this.finalError ? i.error(this.finalError) : i.complete();
      } catch {
      }
    }), this.observers.push(i), s;
  }
  // Unsubscribe is synchronous - we guarantee that no events are sent to
  // any unsubscribed Observer.
  unsubscribeOne(e) {
    this.observers === void 0 || this.observers[e] === void 0 || (delete this.observers[e], this.observerCount -= 1, this.observerCount === 0 && this.onNoObservers !== void 0 && this.onNoObservers(this));
  }
  forEachObserver(e) {
    if (!this.finalized)
      for (let r = 0; r < this.observers.length; r++)
        this.sendOne(r, e);
  }
  // Call the Observer via one of it's callback function. We are careful to
  // confirm that the observe has not been unsubscribed since this asynchronous
  // function had been queued.
  sendOne(e, r) {
    this.task.then(() => {
      if (this.observers !== void 0 && this.observers[e] !== void 0)
        try {
          r(this.observers[e]);
        } catch (n) {
          typeof console < "u" && console.error && console.error(n);
        }
    });
  }
  close(e) {
    this.finalized || (this.finalized = !0, e !== void 0 && (this.finalError = e), this.task.then(() => {
      this.observers = void 0, this.onNoObservers = void 0;
    }));
  }
}
function $r(t, e) {
  if (typeof t != "object" || t === null)
    return !1;
  for (const r of e)
    if (r in t && typeof t[r] == "function")
      return !0;
  return !1;
}
function Le() {
}
/**
 * @license
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Ce(t) {
  return t && t._delegate ? t._delegate : t;
}
class _e {
  /**
   *
   * @param name The public service name, e.g. app, auth, firestore, database
   * @param instanceFactory Service factory responsible for creating the public interface
   * @param type whether the service provided by the component is public or private
   */
  constructor(e, r, n) {
    this.name = e, this.instanceFactory = r, this.type = n, this.multipleInstances = !1, this.serviceProps = {}, this.instantiationMode = "LAZY", this.onInstanceCreated = null;
  }
  setInstantiationMode(e) {
    return this.instantiationMode = e, this;
  }
  setMultipleInstances(e) {
    return this.multipleInstances = e, this;
  }
  setServiceProps(e) {
    return this.serviceProps = e, this;
  }
  setInstanceCreatedCallback(e) {
    return this.onInstanceCreated = e, this;
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var b;
(function(t) {
  t[t.DEBUG = 0] = "DEBUG", t[t.VERBOSE = 1] = "VERBOSE", t[t.INFO = 2] = "INFO", t[t.WARN = 3] = "WARN", t[t.ERROR = 4] = "ERROR", t[t.SILENT = 5] = "SILENT";
})(b || (b = {}));
const xr = {
  debug: b.DEBUG,
  verbose: b.VERBOSE,
  info: b.INFO,
  warn: b.WARN,
  error: b.ERROR,
  silent: b.SILENT
}, Vr = b.INFO, Fr = {
  [b.DEBUG]: "log",
  [b.VERBOSE]: "log",
  [b.INFO]: "info",
  [b.WARN]: "warn",
  [b.ERROR]: "error"
}, Br = (t, e, ...r) => {
  if (e < t.logLevel)
    return;
  const n = (/* @__PURE__ */ new Date()).toISOString(), i = Fr[e];
  if (i)
    console[i](`[${n}]  ${t.name}:`, ...r);
  else
    throw new Error(`Attempted to log a message with an invalid logType (value: ${e})`);
};
class Pt {
  /**
   * Gives you an instance of a Logger to capture messages according to
   * Firebase's logging scheme.
   *
   * @param name The name that the logs will be associated with
   */
  constructor(e) {
    this.name = e, this._logLevel = Vr, this._logHandler = Br, this._userLogHandler = null;
  }
  get logLevel() {
    return this._logLevel;
  }
  set logLevel(e) {
    if (!(e in b))
      throw new TypeError(`Invalid value "${e}" assigned to \`logLevel\``);
    this._logLevel = e;
  }
  // Workaround for setter/getter having to be the same type.
  setLogLevel(e) {
    this._logLevel = typeof e == "string" ? xr[e] : e;
  }
  get logHandler() {
    return this._logHandler;
  }
  set logHandler(e) {
    if (typeof e != "function")
      throw new TypeError("Value assigned to `logHandler` must be a function");
    this._logHandler = e;
  }
  get userLogHandler() {
    return this._userLogHandler;
  }
  set userLogHandler(e) {
    this._userLogHandler = e;
  }
  /**
   * The functions below are all based on the `console` interface
   */
  debug(...e) {
    this._userLogHandler && this._userLogHandler(this, b.DEBUG, ...e), this._logHandler(this, b.DEBUG, ...e);
  }
  log(...e) {
    this._userLogHandler && this._userLogHandler(this, b.VERBOSE, ...e), this._logHandler(this, b.VERBOSE, ...e);
  }
  info(...e) {
    this._userLogHandler && this._userLogHandler(this, b.INFO, ...e), this._logHandler(this, b.INFO, ...e);
  }
  warn(...e) {
    this._userLogHandler && this._userLogHandler(this, b.WARN, ...e), this._logHandler(this, b.WARN, ...e);
  }
  error(...e) {
    this._userLogHandler && this._userLogHandler(this, b.ERROR, ...e), this._logHandler(this, b.ERROR, ...e);
  }
}
const Hr = (t, e) => e.some((r) => t instanceof r);
let Ze, et;
function jr() {
  return Ze || (Ze = [
    IDBDatabase,
    IDBObjectStore,
    IDBIndex,
    IDBCursor,
    IDBTransaction
  ]);
}
function Gr() {
  return et || (et = [
    IDBCursor.prototype.advance,
    IDBCursor.prototype.continue,
    IDBCursor.prototype.continuePrimaryKey
  ]);
}
const Lt = /* @__PURE__ */ new WeakMap(), He = /* @__PURE__ */ new WeakMap(), Mt = /* @__PURE__ */ new WeakMap(), Me = /* @__PURE__ */ new WeakMap(), ze = /* @__PURE__ */ new WeakMap();
function Wr(t) {
  const e = new Promise((r, n) => {
    const i = () => {
      t.removeEventListener("success", s), t.removeEventListener("error", a);
    }, s = () => {
      r(z(t.result)), i();
    }, a = () => {
      n(t.error), i();
    };
    t.addEventListener("success", s), t.addEventListener("error", a);
  });
  return e.then((r) => {
    r instanceof IDBCursor && Lt.set(r, t);
  }).catch(() => {
  }), ze.set(e, t), e;
}
function Kr(t) {
  if (He.has(t))
    return;
  const e = new Promise((r, n) => {
    const i = () => {
      t.removeEventListener("complete", s), t.removeEventListener("error", a), t.removeEventListener("abort", a);
    }, s = () => {
      r(), i();
    }, a = () => {
      n(t.error || new DOMException("AbortError", "AbortError")), i();
    };
    t.addEventListener("complete", s), t.addEventListener("error", a), t.addEventListener("abort", a);
  });
  He.set(t, e);
}
let je = {
  get(t, e, r) {
    if (t instanceof IDBTransaction) {
      if (e === "done")
        return He.get(t);
      if (e === "objectStoreNames")
        return t.objectStoreNames || Mt.get(t);
      if (e === "store")
        return r.objectStoreNames[1] ? void 0 : r.objectStore(r.objectStoreNames[0]);
    }
    return z(t[e]);
  },
  set(t, e, r) {
    return t[e] = r, !0;
  },
  has(t, e) {
    return t instanceof IDBTransaction && (e === "done" || e === "store") ? !0 : e in t;
  }
};
function Yr(t) {
  je = t(je);
}
function zr(t) {
  return t === IDBDatabase.prototype.transaction && !("objectStoreNames" in IDBTransaction.prototype) ? function(e, ...r) {
    const n = t.call(ke(this), e, ...r);
    return Mt.set(n, e.sort ? e.sort() : [e]), z(n);
  } : Gr().includes(t) ? function(...e) {
    return t.apply(ke(this), e), z(Lt.get(this));
  } : function(...e) {
    return z(t.apply(ke(this), e));
  };
}
function qr(t) {
  return typeof t == "function" ? zr(t) : (t instanceof IDBTransaction && Kr(t), Hr(t, jr()) ? new Proxy(t, je) : t);
}
function z(t) {
  if (t instanceof IDBRequest)
    return Wr(t);
  if (Me.has(t))
    return Me.get(t);
  const e = qr(t);
  return e !== t && (Me.set(t, e), ze.set(e, t)), e;
}
const ke = (t) => ze.get(t);
function Jr(t, e, { blocked: r, upgrade: n, blocking: i, terminated: s } = {}) {
  const a = indexedDB.open(t, e), c = z(a);
  return n && a.addEventListener("upgradeneeded", (o) => {
    n(z(a.result), o.oldVersion, o.newVersion, z(a.transaction), o);
  }), r && a.addEventListener("blocked", (o) => r(
    // Casting due to https://github.com/microsoft/TypeScript-DOM-lib-generator/pull/1405
    o.oldVersion,
    o.newVersion,
    o
  )), c.then((o) => {
    s && o.addEventListener("close", () => s()), i && o.addEventListener("versionchange", (u) => i(u.oldVersion, u.newVersion, u));
  }).catch(() => {
  }), c;
}
const Xr = ["get", "getKey", "getAll", "getAllKeys", "count"], Qr = ["put", "add", "delete", "clear"], Ue = /* @__PURE__ */ new Map();
function tt(t, e) {
  if (!(t instanceof IDBDatabase && !(e in t) && typeof e == "string"))
    return;
  if (Ue.get(e))
    return Ue.get(e);
  const r = e.replace(/FromIndex$/, ""), n = e !== r, i = Qr.includes(r);
  if (
    // Bail if the target doesn't exist on the target. Eg, getAll isn't in Edge.
    !(r in (n ? IDBIndex : IDBObjectStore).prototype) || !(i || Xr.includes(r))
  )
    return;
  const s = async function(a, ...c) {
    const o = this.transaction(a, i ? "readwrite" : "readonly");
    let u = o.store;
    return n && (u = u.index(c.shift())), (await Promise.all([
      u[r](...c),
      i && o.done
    ]))[0];
  };
  return Ue.set(e, s), s;
}
Yr((t) => ({
  ...t,
  get: (e, r, n) => tt(e, r) || t.get(e, r, n),
  has: (e, r) => !!tt(e, r) || t.has(e, r)
}));
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Zr {
  constructor(e) {
    this.container = e;
  }
  // In initial implementation, this will be called by installations on
  // auth token refresh, and installations will send this string.
  getPlatformInfoString() {
    return this.container.getProviders().map((r) => {
      if (en(r)) {
        const n = r.getImmediate();
        return `${n.library}/${n.version}`;
      } else
        return null;
    }).filter((r) => r).join(" ");
  }
}
function en(t) {
  const e = t.getComponent();
  return (e == null ? void 0 : e.type) === "VERSION";
}
const Ge = "@firebase/app", rt = "0.10.5";
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const te = new Pt("@firebase/app"), tn = "@firebase/app-compat", rn = "@firebase/analytics-compat", nn = "@firebase/analytics", sn = "@firebase/app-check-compat", an = "@firebase/app-check", on = "@firebase/auth", cn = "@firebase/auth-compat", un = "@firebase/database", ln = "@firebase/database-compat", dn = "@firebase/functions", hn = "@firebase/functions-compat", fn = "@firebase/installations", pn = "@firebase/installations-compat", _n = "@firebase/messaging", mn = "@firebase/messaging-compat", gn = "@firebase/performance", En = "@firebase/performance-compat", In = "@firebase/remote-config", yn = "@firebase/remote-config-compat", vn = "@firebase/storage", bn = "@firebase/storage-compat", Sn = "@firebase/firestore", An = "@firebase/vertexai-preview", Tn = "@firebase/firestore-compat", wn = "firebase", Nn = "10.12.2", On = {
  [Ge]: "fire-core",
  [tn]: "fire-core-compat",
  [nn]: "fire-analytics",
  [rn]: "fire-analytics-compat",
  [an]: "fire-app-check",
  [sn]: "fire-app-check-compat",
  [on]: "fire-auth",
  [cn]: "fire-auth-compat",
  [un]: "fire-rtdb",
  [ln]: "fire-rtdb-compat",
  [dn]: "fire-fn",
  [hn]: "fire-fn-compat",
  [fn]: "fire-iid",
  [pn]: "fire-iid-compat",
  [_n]: "fire-fcm",
  [mn]: "fire-fcm-compat",
  [gn]: "fire-perf",
  [En]: "fire-perf-compat",
  [In]: "fire-rc",
  [yn]: "fire-rc-compat",
  [vn]: "fire-gcs",
  [bn]: "fire-gcs-compat",
  [Sn]: "fire-fst",
  [Tn]: "fire-fst-compat",
  [An]: "fire-vertex",
  "fire-js": "fire-js",
  [wn]: "fire-js-all"
};
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Cn = /* @__PURE__ */ new Map(), Dn = /* @__PURE__ */ new Map(), nt = /* @__PURE__ */ new Map();
function it(t, e) {
  try {
    t.container.addComponent(e);
  } catch (r) {
    te.debug(`Component ${e.name} failed to register with FirebaseApp ${t.name}`, r);
  }
}
function me(t) {
  const e = t.name;
  if (nt.has(e))
    return te.debug(`There were multiple attempts to register component ${e}.`), !1;
  nt.set(e, t);
  for (const r of Cn.values())
    it(r, t);
  for (const r of Dn.values())
    it(r, t);
  return !0;
}
function de(t) {
  return t.settings !== void 0;
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Rn = {
  "no-app": "No Firebase App '{$appName}' has been created - call initializeApp() first",
  "bad-app-name": "Illegal App name: '{$appName}'",
  "duplicate-app": "Firebase App named '{$appName}' already exists with different options or config",
  "app-deleted": "Firebase App named '{$appName}' already deleted",
  "server-app-deleted": "Firebase Server App has been deleted",
  "no-options": "Need to provide options, when not being deployed to hosting via source.",
  "invalid-app-argument": "firebase.{$appName}() takes either no argument or a Firebase App instance.",
  "invalid-log-argument": "First argument to `onLog` must be null or a function.",
  "idb-open": "Error thrown when opening IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-get": "Error thrown when reading from IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-set": "Error thrown when writing to IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-delete": "Error thrown when deleting from IndexedDB. Original error: {$originalErrorMessage}.",
  "finalization-registry-not-supported": "FirebaseServerApp deleteOnDeref field defined but the JS runtime does not support FinalizationRegistry.",
  "invalid-server-app-environment": "FirebaseServerApp is not for use in browser environments."
}, qe = new Ee("app", "Firebase", Rn);
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Je = Nn;
function he(t, e, r) {
  var n;
  let i = (n = On[t]) !== null && n !== void 0 ? n : t;
  r && (i += `-${r}`);
  const s = i.match(/\s|\//), a = e.match(/\s|\//);
  if (s || a) {
    const c = [
      `Unable to register library "${i}" with version "${e}":`
    ];
    s && c.push(`library name "${i}" contains illegal characters (whitespace or "/")`), s && a && c.push("and"), a && c.push(`version name "${e}" contains illegal characters (whitespace or "/")`), te.warn(c.join(" "));
    return;
  }
  me(new _e(
    `${i}-version`,
    () => ({ library: i, version: e }),
    "VERSION"
    /* ComponentType.VERSION */
  ));
}
/**
 * @license
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Pn = "firebase-heartbeat-database", Ln = 1, ge = "firebase-heartbeat-store";
let $e = null;
function kt() {
  return $e || ($e = Jr(Pn, Ln, {
    upgrade: (t, e) => {
      switch (e) {
        case 0:
          try {
            t.createObjectStore(ge);
          } catch (r) {
            console.warn(r);
          }
      }
    }
  }).catch((t) => {
    throw qe.create("idb-open", {
      originalErrorMessage: t.message
    });
  })), $e;
}
async function Mn(t) {
  try {
    const r = (await kt()).transaction(ge), n = await r.objectStore(ge).get(Ut(t));
    return await r.done, n;
  } catch (e) {
    if (e instanceof re)
      te.warn(e.message);
    else {
      const r = qe.create("idb-get", {
        originalErrorMessage: e == null ? void 0 : e.message
      });
      te.warn(r.message);
    }
  }
}
async function st(t, e) {
  try {
    const n = (await kt()).transaction(ge, "readwrite");
    await n.objectStore(ge).put(e, Ut(t)), await n.done;
  } catch (r) {
    if (r instanceof re)
      te.warn(r.message);
    else {
      const n = qe.create("idb-set", {
        originalErrorMessage: r == null ? void 0 : r.message
      });
      te.warn(n.message);
    }
  }
}
function Ut(t) {
  return `${t.name}!${t.options.appId}`;
}
/**
 * @license
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const kn = 1024, Un = 30 * 24 * 60 * 60 * 1e3;
class $n {
  constructor(e) {
    this.container = e, this._heartbeatsCache = null;
    const r = this.container.getProvider("app").getImmediate();
    this._storage = new Vn(r), this._heartbeatsCachePromise = this._storage.read().then((n) => (this._heartbeatsCache = n, n));
  }
  /**
   * Called to report a heartbeat. The function will generate
   * a HeartbeatsByUserAgent object, update heartbeatsCache, and persist it
   * to IndexedDB.
   * Note that we only store one heartbeat per day. So if a heartbeat for today is
   * already logged, subsequent calls to this function in the same day will be ignored.
   */
  async triggerHeartbeat() {
    var e, r;
    const i = this.container.getProvider("platform-logger").getImmediate().getPlatformInfoString(), s = at();
    if (!(((e = this._heartbeatsCache) === null || e === void 0 ? void 0 : e.heartbeats) == null && (this._heartbeatsCache = await this._heartbeatsCachePromise, ((r = this._heartbeatsCache) === null || r === void 0 ? void 0 : r.heartbeats) == null)) && !(this._heartbeatsCache.lastSentHeartbeatDate === s || this._heartbeatsCache.heartbeats.some((a) => a.date === s)))
      return this._heartbeatsCache.heartbeats.push({ date: s, agent: i }), this._heartbeatsCache.heartbeats = this._heartbeatsCache.heartbeats.filter((a) => {
        const c = new Date(a.date).valueOf();
        return Date.now() - c <= Un;
      }), this._storage.overwrite(this._heartbeatsCache);
  }
  /**
   * Returns a base64 encoded string which can be attached to the heartbeat-specific header directly.
   * It also clears all heartbeats from memory as well as in IndexedDB.
   *
   * NOTE: Consuming product SDKs should not send the header if this method
   * returns an empty string.
   */
  async getHeartbeatsHeader() {
    var e;
    if (this._heartbeatsCache === null && await this._heartbeatsCachePromise, ((e = this._heartbeatsCache) === null || e === void 0 ? void 0 : e.heartbeats) == null || this._heartbeatsCache.heartbeats.length === 0)
      return "";
    const r = at(), { heartbeatsToSend: n, unsentEntries: i } = xn(this._heartbeatsCache.heartbeats), s = Ct(JSON.stringify({ version: 2, heartbeats: n }));
    return this._heartbeatsCache.lastSentHeartbeatDate = r, i.length > 0 ? (this._heartbeatsCache.heartbeats = i, await this._storage.overwrite(this._heartbeatsCache)) : (this._heartbeatsCache.heartbeats = [], this._storage.overwrite(this._heartbeatsCache)), s;
  }
}
function at() {
  return (/* @__PURE__ */ new Date()).toISOString().substring(0, 10);
}
function xn(t, e = kn) {
  const r = [];
  let n = t.slice();
  for (const i of t) {
    const s = r.find((a) => a.agent === i.agent);
    if (s) {
      if (s.dates.push(i.date), ot(r) > e) {
        s.dates.pop();
        break;
      }
    } else if (r.push({
      agent: i.agent,
      dates: [i.date]
    }), ot(r) > e) {
      r.pop();
      break;
    }
    n = n.slice(1);
  }
  return {
    heartbeatsToSend: r,
    unsentEntries: n
  };
}
class Vn {
  constructor(e) {
    this.app = e, this._canUseIndexedDBPromise = this.runIndexedDBEnvironmentCheck();
  }
  async runIndexedDBEnvironmentCheck() {
    return Dr() ? Rr().then(() => !0).catch(() => !1) : !1;
  }
  /**
   * Read all heartbeats.
   */
  async read() {
    if (await this._canUseIndexedDBPromise) {
      const r = await Mn(this.app);
      return r != null && r.heartbeats ? r : { heartbeats: [] };
    } else
      return { heartbeats: [] };
  }
  // overwrite the storage with the provided heartbeats
  async overwrite(e) {
    var r;
    if (await this._canUseIndexedDBPromise) {
      const i = await this.read();
      return st(this.app, {
        lastSentHeartbeatDate: (r = e.lastSentHeartbeatDate) !== null && r !== void 0 ? r : i.lastSentHeartbeatDate,
        heartbeats: e.heartbeats
      });
    } else
      return;
  }
  // add heartbeats
  async add(e) {
    var r;
    if (await this._canUseIndexedDBPromise) {
      const i = await this.read();
      return st(this.app, {
        lastSentHeartbeatDate: (r = e.lastSentHeartbeatDate) !== null && r !== void 0 ? r : i.lastSentHeartbeatDate,
        heartbeats: [
          ...i.heartbeats,
          ...e.heartbeats
        ]
      });
    } else
      return;
  }
}
function ot(t) {
  return Ct(
    // heartbeatsCache wrapper properties
    JSON.stringify({ version: 2, heartbeats: t })
  ).length;
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Fn(t) {
  me(new _e(
    "platform-logger",
    (e) => new Zr(e),
    "PRIVATE"
    /* ComponentType.PRIVATE */
  )), me(new _e(
    "heartbeat",
    (e) => new $n(e),
    "PRIVATE"
    /* ComponentType.PRIVATE */
  )), he(Ge, rt, t), he(Ge, rt, "esm2017"), he("fire-js", "");
}
Fn("");
function $t(t, e) {
  var r = {};
  for (var n in t)
    Object.prototype.hasOwnProperty.call(t, n) && e.indexOf(n) < 0 && (r[n] = t[n]);
  if (t != null && typeof Object.getOwnPropertySymbols == "function")
    for (var i = 0, n = Object.getOwnPropertySymbols(t); i < n.length; i++)
      e.indexOf(n[i]) < 0 && Object.prototype.propertyIsEnumerable.call(t, n[i]) && (r[n[i]] = t[n[i]]);
  return r;
}
function xt() {
  return {
    "dependent-sdk-initialized-before-auth": "Another Firebase SDK was initialized and is trying to use Auth before Auth is initialized. Please be sure to call `initializeAuth` or `getAuth` before starting any other Firebase SDK."
  };
}
const Bn = xt, Vt = new Ee("auth", "Firebase", xt()), Hn = {
  ADMIN_ONLY_OPERATION: "auth/admin-restricted-operation",
  ARGUMENT_ERROR: "auth/argument-error",
  APP_NOT_AUTHORIZED: "auth/app-not-authorized",
  APP_NOT_INSTALLED: "auth/app-not-installed",
  CAPTCHA_CHECK_FAILED: "auth/captcha-check-failed",
  CODE_EXPIRED: "auth/code-expired",
  CORDOVA_NOT_READY: "auth/cordova-not-ready",
  CORS_UNSUPPORTED: "auth/cors-unsupported",
  CREDENTIAL_ALREADY_IN_USE: "auth/credential-already-in-use",
  CREDENTIAL_MISMATCH: "auth/custom-token-mismatch",
  CREDENTIAL_TOO_OLD_LOGIN_AGAIN: "auth/requires-recent-login",
  DEPENDENT_SDK_INIT_BEFORE_AUTH: "auth/dependent-sdk-initialized-before-auth",
  DYNAMIC_LINK_NOT_ACTIVATED: "auth/dynamic-link-not-activated",
  EMAIL_CHANGE_NEEDS_VERIFICATION: "auth/email-change-needs-verification",
  EMAIL_EXISTS: "auth/email-already-in-use",
  EMULATOR_CONFIG_FAILED: "auth/emulator-config-failed",
  EXPIRED_OOB_CODE: "auth/expired-action-code",
  EXPIRED_POPUP_REQUEST: "auth/cancelled-popup-request",
  INTERNAL_ERROR: "auth/internal-error",
  INVALID_API_KEY: "auth/invalid-api-key",
  INVALID_APP_CREDENTIAL: "auth/invalid-app-credential",
  INVALID_APP_ID: "auth/invalid-app-id",
  INVALID_AUTH: "auth/invalid-user-token",
  INVALID_AUTH_EVENT: "auth/invalid-auth-event",
  INVALID_CERT_HASH: "auth/invalid-cert-hash",
  INVALID_CODE: "auth/invalid-verification-code",
  INVALID_CONTINUE_URI: "auth/invalid-continue-uri",
  INVALID_CORDOVA_CONFIGURATION: "auth/invalid-cordova-configuration",
  INVALID_CUSTOM_TOKEN: "auth/invalid-custom-token",
  INVALID_DYNAMIC_LINK_DOMAIN: "auth/invalid-dynamic-link-domain",
  INVALID_EMAIL: "auth/invalid-email",
  INVALID_EMULATOR_SCHEME: "auth/invalid-emulator-scheme",
  INVALID_IDP_RESPONSE: "auth/invalid-credential",
  INVALID_LOGIN_CREDENTIALS: "auth/invalid-credential",
  INVALID_MESSAGE_PAYLOAD: "auth/invalid-message-payload",
  INVALID_MFA_SESSION: "auth/invalid-multi-factor-session",
  INVALID_OAUTH_CLIENT_ID: "auth/invalid-oauth-client-id",
  INVALID_OAUTH_PROVIDER: "auth/invalid-oauth-provider",
  INVALID_OOB_CODE: "auth/invalid-action-code",
  INVALID_ORIGIN: "auth/unauthorized-domain",
  INVALID_PASSWORD: "auth/wrong-password",
  INVALID_PERSISTENCE: "auth/invalid-persistence-type",
  INVALID_PHONE_NUMBER: "auth/invalid-phone-number",
  INVALID_PROVIDER_ID: "auth/invalid-provider-id",
  INVALID_RECIPIENT_EMAIL: "auth/invalid-recipient-email",
  INVALID_SENDER: "auth/invalid-sender",
  INVALID_SESSION_INFO: "auth/invalid-verification-id",
  INVALID_TENANT_ID: "auth/invalid-tenant-id",
  MFA_INFO_NOT_FOUND: "auth/multi-factor-info-not-found",
  MFA_REQUIRED: "auth/multi-factor-auth-required",
  MISSING_ANDROID_PACKAGE_NAME: "auth/missing-android-pkg-name",
  MISSING_APP_CREDENTIAL: "auth/missing-app-credential",
  MISSING_AUTH_DOMAIN: "auth/auth-domain-config-required",
  MISSING_CODE: "auth/missing-verification-code",
  MISSING_CONTINUE_URI: "auth/missing-continue-uri",
  MISSING_IFRAME_START: "auth/missing-iframe-start",
  MISSING_IOS_BUNDLE_ID: "auth/missing-ios-bundle-id",
  MISSING_OR_INVALID_NONCE: "auth/missing-or-invalid-nonce",
  MISSING_MFA_INFO: "auth/missing-multi-factor-info",
  MISSING_MFA_SESSION: "auth/missing-multi-factor-session",
  MISSING_PHONE_NUMBER: "auth/missing-phone-number",
  MISSING_SESSION_INFO: "auth/missing-verification-id",
  MODULE_DESTROYED: "auth/app-deleted",
  NEED_CONFIRMATION: "auth/account-exists-with-different-credential",
  NETWORK_REQUEST_FAILED: "auth/network-request-failed",
  NULL_USER: "auth/null-user",
  NO_AUTH_EVENT: "auth/no-auth-event",
  NO_SUCH_PROVIDER: "auth/no-such-provider",
  OPERATION_NOT_ALLOWED: "auth/operation-not-allowed",
  OPERATION_NOT_SUPPORTED: "auth/operation-not-supported-in-this-environment",
  POPUP_BLOCKED: "auth/popup-blocked",
  POPUP_CLOSED_BY_USER: "auth/popup-closed-by-user",
  PROVIDER_ALREADY_LINKED: "auth/provider-already-linked",
  QUOTA_EXCEEDED: "auth/quota-exceeded",
  REDIRECT_CANCELLED_BY_USER: "auth/redirect-cancelled-by-user",
  REDIRECT_OPERATION_PENDING: "auth/redirect-operation-pending",
  REJECTED_CREDENTIAL: "auth/rejected-credential",
  SECOND_FACTOR_ALREADY_ENROLLED: "auth/second-factor-already-in-use",
  SECOND_FACTOR_LIMIT_EXCEEDED: "auth/maximum-second-factor-count-exceeded",
  TENANT_ID_MISMATCH: "auth/tenant-id-mismatch",
  TIMEOUT: "auth/timeout",
  TOKEN_EXPIRED: "auth/user-token-expired",
  TOO_MANY_ATTEMPTS_TRY_LATER: "auth/too-many-requests",
  UNAUTHORIZED_DOMAIN: "auth/unauthorized-continue-uri",
  UNSUPPORTED_FIRST_FACTOR: "auth/unsupported-first-factor",
  UNSUPPORTED_PERSISTENCE: "auth/unsupported-persistence-type",
  UNSUPPORTED_TENANT_OPERATION: "auth/unsupported-tenant-operation",
  UNVERIFIED_EMAIL: "auth/unverified-email",
  USER_CANCELLED: "auth/user-cancelled",
  USER_DELETED: "auth/user-not-found",
  USER_DISABLED: "auth/user-disabled",
  USER_MISMATCH: "auth/user-mismatch",
  USER_SIGNED_OUT: "auth/user-signed-out",
  WEAK_PASSWORD: "auth/weak-password",
  WEB_STORAGE_UNSUPPORTED: "auth/web-storage-unsupported",
  ALREADY_INITIALIZED: "auth/already-initialized",
  RECAPTCHA_NOT_ENABLED: "auth/recaptcha-not-enabled",
  MISSING_RECAPTCHA_TOKEN: "auth/missing-recaptcha-token",
  INVALID_RECAPTCHA_TOKEN: "auth/invalid-recaptcha-token",
  INVALID_RECAPTCHA_ACTION: "auth/invalid-recaptcha-action",
  MISSING_CLIENT_TYPE: "auth/missing-client-type",
  MISSING_RECAPTCHA_VERSION: "auth/missing-recaptcha-version",
  INVALID_RECAPTCHA_VERSION: "auth/invalid-recaptcha-version",
  INVALID_REQ_TYPE: "auth/invalid-req-type"
};
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Te = new Pt("@firebase/auth");
function jn(t, ...e) {
  Te.logLevel <= b.WARN && Te.warn(`Auth (${Je}): ${t}`, ...e);
}
function Se(t, ...e) {
  Te.logLevel <= b.ERROR && Te.error(`Auth (${Je}): ${t}`, ...e);
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function ct(t, ...e) {
  throw Xe(t, ...e);
}
function Ft(t, ...e) {
  return Xe(t, ...e);
}
function Bt(t, e, r) {
  const n = Object.assign(Object.assign({}, Bn()), { [e]: r });
  return new Ee("auth", "Firebase", n).create(e, {
    appName: t.name
  });
}
function Ae(t) {
  return Bt(t, "operation-not-supported-in-this-environment", "Operations that alter the current user are not supported in conjunction with FirebaseServerApp");
}
function Xe(t, ...e) {
  if (typeof t != "string") {
    const r = e[0], n = [...e.slice(1)];
    return n[0] && (n[0].appName = t.name), t._errorFactory.create(r, ...n);
  }
  return Vt.create(t, ...e);
}
function y(t, e, ...r) {
  if (!t)
    throw Xe(e, ...r);
}
function fe(t) {
  const e = "INTERNAL ASSERTION FAILED: " + t;
  throw Se(e), new Error(e);
}
function we(t, e) {
  t || fe(e);
}
function Gn() {
  return ut() === "http:" || ut() === "https:";
}
function ut() {
  var t;
  return typeof self < "u" && ((t = self.location) === null || t === void 0 ? void 0 : t.protocol) || null;
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Wn() {
  return typeof navigator < "u" && navigator && "onLine" in navigator && typeof navigator.onLine == "boolean" && // Apply only for traditional web apps and Chrome extensions.
  // This is especially true for Cordova apps which have unreliable
  // navigator.onLine behavior unless cordova-plugin-network-information is
  // installed which overwrites the native navigator.onLine value and
  // defines navigator.connection.
  (Gn() || Or() || "connection" in navigator) ? navigator.onLine : !0;
}
function Kn() {
  if (typeof navigator > "u")
    return null;
  const t = navigator;
  return (
    // Most reliable, but only supported in Chrome/Firefox.
    t.languages && t.languages[0] || // Supported in most browsers, but returns the language of the browser
    // UI, not the language set in browser settings.
    t.language || // Couldn't determine language.
    null
  );
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Ie {
  constructor(e, r) {
    this.shortDelay = e, this.longDelay = r, we(r > e, "Short delay should be less than long delay!"), this.isMobile = Nr() || Cr();
  }
  get() {
    return Wn() ? this.isMobile ? this.longDelay : this.shortDelay : Math.min(5e3, this.shortDelay);
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Yn(t, e) {
  we(t.emulator, "Emulator should always be set here");
  const { url: r } = t.emulator;
  return e ? `${r}${e.startsWith("/") ? e.slice(1) : e}` : r;
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Ht {
  static initialize(e, r, n) {
    this.fetchImpl = e, r && (this.headersImpl = r), n && (this.responseImpl = n);
  }
  static fetch() {
    if (this.fetchImpl)
      return this.fetchImpl;
    if (typeof self < "u" && "fetch" in self)
      return self.fetch;
    if (typeof globalThis < "u" && globalThis.fetch)
      return globalThis.fetch;
    if (typeof fetch < "u")
      return fetch;
    fe("Could not find fetch implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
  static headers() {
    if (this.headersImpl)
      return this.headersImpl;
    if (typeof self < "u" && "Headers" in self)
      return self.Headers;
    if (typeof globalThis < "u" && globalThis.Headers)
      return globalThis.Headers;
    if (typeof Headers < "u")
      return Headers;
    fe("Could not find Headers implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
  static response() {
    if (this.responseImpl)
      return this.responseImpl;
    if (typeof self < "u" && "Response" in self)
      return self.Response;
    if (typeof globalThis < "u" && globalThis.Response)
      return globalThis.Response;
    if (typeof Response < "u")
      return Response;
    fe("Could not find Response implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const zn = {
  // Custom token errors.
  CREDENTIAL_MISMATCH: "custom-token-mismatch",
  // This can only happen if the SDK sends a bad request.
  MISSING_CUSTOM_TOKEN: "internal-error",
  // Create Auth URI errors.
  INVALID_IDENTIFIER: "invalid-email",
  // This can only happen if the SDK sends a bad request.
  MISSING_CONTINUE_URI: "internal-error",
  // Sign in with email and password errors (some apply to sign up too).
  INVALID_PASSWORD: "wrong-password",
  // This can only happen if the SDK sends a bad request.
  MISSING_PASSWORD: "missing-password",
  // Thrown if Email Enumeration Protection is enabled in the project and the email or password is
  // invalid.
  INVALID_LOGIN_CREDENTIALS: "invalid-credential",
  // Sign up with email and password errors.
  EMAIL_EXISTS: "email-already-in-use",
  PASSWORD_LOGIN_DISABLED: "operation-not-allowed",
  // Verify assertion for sign in with credential errors:
  INVALID_IDP_RESPONSE: "invalid-credential",
  INVALID_PENDING_TOKEN: "invalid-credential",
  FEDERATED_USER_ID_ALREADY_LINKED: "credential-already-in-use",
  // This can only happen if the SDK sends a bad request.
  MISSING_REQ_TYPE: "internal-error",
  // Send Password reset email errors:
  EMAIL_NOT_FOUND: "user-not-found",
  RESET_PASSWORD_EXCEED_LIMIT: "too-many-requests",
  EXPIRED_OOB_CODE: "expired-action-code",
  INVALID_OOB_CODE: "invalid-action-code",
  // This can only happen if the SDK sends a bad request.
  MISSING_OOB_CODE: "internal-error",
  // Operations that require ID token in request:
  CREDENTIAL_TOO_OLD_LOGIN_AGAIN: "requires-recent-login",
  INVALID_ID_TOKEN: "invalid-user-token",
  TOKEN_EXPIRED: "user-token-expired",
  USER_NOT_FOUND: "user-token-expired",
  // Other errors.
  TOO_MANY_ATTEMPTS_TRY_LATER: "too-many-requests",
  PASSWORD_DOES_NOT_MEET_REQUIREMENTS: "password-does-not-meet-requirements",
  // Phone Auth related errors.
  INVALID_CODE: "invalid-verification-code",
  INVALID_SESSION_INFO: "invalid-verification-id",
  INVALID_TEMPORARY_PROOF: "invalid-credential",
  MISSING_SESSION_INFO: "missing-verification-id",
  SESSION_EXPIRED: "code-expired",
  // Other action code errors when additional settings passed.
  // MISSING_CONTINUE_URI is getting mapped to INTERNAL_ERROR above.
  // This is OK as this error will be caught by client side validation.
  MISSING_ANDROID_PACKAGE_NAME: "missing-android-pkg-name",
  UNAUTHORIZED_DOMAIN: "unauthorized-continue-uri",
  // getProjectConfig errors when clientId is passed.
  INVALID_OAUTH_CLIENT_ID: "invalid-oauth-client-id",
  // User actions (sign-up or deletion) disabled errors.
  ADMIN_ONLY_OPERATION: "admin-restricted-operation",
  // Multi factor related errors.
  INVALID_MFA_PENDING_CREDENTIAL: "invalid-multi-factor-session",
  MFA_ENROLLMENT_NOT_FOUND: "multi-factor-info-not-found",
  MISSING_MFA_ENROLLMENT_ID: "missing-multi-factor-info",
  MISSING_MFA_PENDING_CREDENTIAL: "missing-multi-factor-session",
  SECOND_FACTOR_EXISTS: "second-factor-already-in-use",
  SECOND_FACTOR_LIMIT_EXCEEDED: "maximum-second-factor-count-exceeded",
  // Blocking functions related errors.
  BLOCKING_FUNCTION_ERROR_RESPONSE: "internal-error",
  // Recaptcha related errors.
  RECAPTCHA_NOT_ENABLED: "recaptcha-not-enabled",
  MISSING_RECAPTCHA_TOKEN: "missing-recaptcha-token",
  INVALID_RECAPTCHA_TOKEN: "invalid-recaptcha-token",
  INVALID_RECAPTCHA_ACTION: "invalid-recaptcha-action",
  MISSING_CLIENT_TYPE: "missing-client-type",
  MISSING_RECAPTCHA_VERSION: "missing-recaptcha-version",
  INVALID_RECAPTCHA_VERSION: "invalid-recaptcha-version",
  INVALID_REQ_TYPE: "invalid-req-type"
  /* AuthErrorCode.INVALID_REQ_TYPE */
};
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const qn = new Ie(3e4, 6e4);
function jt(t, e) {
  return t.tenantId && !e.tenantId ? Object.assign(Object.assign({}, e), { tenantId: t.tenantId }) : e;
}
async function De(t, e, r, n, i = {}) {
  return Gt(t, i, async () => {
    let s = {}, a = {};
    n && (e === "GET" ? a = n : s = {
      body: JSON.stringify(n)
    });
    const c = Rt(Object.assign({ key: t.config.apiKey }, a)).slice(1), o = await t._getAdditionalHeaders();
    return o[
      "Content-Type"
      /* HttpHeader.CONTENT_TYPE */
    ] = "application/json", t.languageCode && (o[
      "X-Firebase-Locale"
      /* HttpHeader.X_FIREBASE_LOCALE */
    ] = t.languageCode), Ht.fetch()(Wt(t, t.config.apiHost, r, c), Object.assign({
      method: e,
      headers: o,
      referrerPolicy: "no-referrer"
    }, s));
  });
}
async function Gt(t, e, r) {
  t._canInitEmulator = !1;
  const n = Object.assign(Object.assign({}, zn), e);
  try {
    const i = new Jn(t), s = await Promise.race([
      r(),
      i.promise
    ]);
    i.clearNetworkTimeout();
    const a = await s.json();
    if ("needConfirmation" in a)
      throw be(t, "account-exists-with-different-credential", a);
    if (s.ok && !("errorMessage" in a))
      return a;
    {
      const c = s.ok ? a.errorMessage : a.error.message, [o, u] = c.split(" : ");
      if (o === "FEDERATED_USER_ID_ALREADY_LINKED")
        throw be(t, "credential-already-in-use", a);
      if (o === "EMAIL_EXISTS")
        throw be(t, "email-already-in-use", a);
      if (o === "USER_DISABLED")
        throw be(t, "user-disabled", a);
      const _ = n[o] || o.toLowerCase().replace(/[_\s]+/g, "-");
      if (u)
        throw Bt(t, _, u);
      ct(t, _);
    }
  } catch (i) {
    if (i instanceof re)
      throw i;
    ct(t, "network-request-failed", { message: String(i) });
  }
}
function Wt(t, e, r, n) {
  const i = `${e}${r}?${n}`;
  return t.config.emulator ? Yn(t.config, i) : `${t.config.apiScheme}://${i}`;
}
class Jn {
  constructor(e) {
    this.auth = e, this.timer = null, this.promise = new Promise((r, n) => {
      this.timer = setTimeout(() => n(Ft(
        this.auth,
        "network-request-failed"
        /* AuthErrorCode.NETWORK_REQUEST_FAILED */
      )), qn.get());
    });
  }
  clearNetworkTimeout() {
    clearTimeout(this.timer);
  }
}
function be(t, e, r) {
  const n = {
    appName: t.name
  };
  r.email && (n.email = r.email), r.phoneNumber && (n.phoneNumber = r.phoneNumber);
  const i = Ft(t, e, n);
  return i.customData._tokenResponse = r, i;
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
async function Xn(t, e) {
  return De(t, "POST", "/v1/accounts:delete", e);
}
async function Kt(t, e) {
  return De(t, "POST", "/v1/accounts:lookup", e);
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function pe(t) {
  if (t)
    try {
      const e = new Date(Number(t));
      if (!isNaN(e.getTime()))
        return e.toUTCString();
    } catch {
    }
}
async function Qn(t, e = !1) {
  const r = Ce(t), n = await r.getIdToken(e), i = Yt(n);
  y(
    i && i.exp && i.auth_time && i.iat,
    r.auth,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  );
  const s = typeof i.firebase == "object" ? i.firebase : void 0, a = s == null ? void 0 : s.sign_in_provider;
  return {
    claims: i,
    token: n,
    authTime: pe(xe(i.auth_time)),
    issuedAtTime: pe(xe(i.iat)),
    expirationTime: pe(xe(i.exp)),
    signInProvider: a || null,
    signInSecondFactor: (s == null ? void 0 : s.sign_in_second_factor) || null
  };
}
function xe(t) {
  return Number(t) * 1e3;
}
function Yt(t) {
  const [e, r, n] = t.split(".");
  if (e === void 0 || r === void 0 || n === void 0)
    return Se("JWT malformed, contained fewer than 3 sections"), null;
  try {
    const i = Dt(r);
    return i ? JSON.parse(i) : (Se("Failed to decode base64 JWT payload"), null);
  } catch (i) {
    return Se("Caught error parsing JWT payload as JSON", i == null ? void 0 : i.toString()), null;
  }
}
function lt(t) {
  const e = Yt(t);
  return y(
    e,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), y(
    typeof e.exp < "u",
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), y(
    typeof e.iat < "u",
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), Number(e.exp) - Number(e.iat);
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
async function We(t, e, r = !1) {
  if (r)
    return e;
  try {
    return await e;
  } catch (n) {
    throw n instanceof re && Zn(n) && t.auth.currentUser === t && await t.auth.signOut(), n;
  }
}
function Zn({ code: t }) {
  return t === "auth/user-disabled" || t === "auth/user-token-expired";
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class ei {
  constructor(e) {
    this.user = e, this.isRunning = !1, this.timerId = null, this.errorBackoff = 3e4;
  }
  _start() {
    this.isRunning || (this.isRunning = !0, this.schedule());
  }
  _stop() {
    this.isRunning && (this.isRunning = !1, this.timerId !== null && clearTimeout(this.timerId));
  }
  getInterval(e) {
    var r;
    if (e) {
      const n = this.errorBackoff;
      return this.errorBackoff = Math.min(
        this.errorBackoff * 2,
        96e4
        /* Duration.RETRY_BACKOFF_MAX */
      ), n;
    } else {
      this.errorBackoff = 3e4;
      const i = ((r = this.user.stsTokenManager.expirationTime) !== null && r !== void 0 ? r : 0) - Date.now() - 3e5;
      return Math.max(0, i);
    }
  }
  schedule(e = !1) {
    if (!this.isRunning)
      return;
    const r = this.getInterval(e);
    this.timerId = setTimeout(async () => {
      await this.iteration();
    }, r);
  }
  async iteration() {
    try {
      await this.user.getIdToken(!0);
    } catch (e) {
      (e == null ? void 0 : e.code) === "auth/network-request-failed" && this.schedule(
        /* wasError */
        !0
      );
      return;
    }
    this.schedule();
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Ke {
  constructor(e, r) {
    this.createdAt = e, this.lastLoginAt = r, this._initializeTime();
  }
  _initializeTime() {
    this.lastSignInTime = pe(this.lastLoginAt), this.creationTime = pe(this.createdAt);
  }
  _copy(e) {
    this.createdAt = e.createdAt, this.lastLoginAt = e.lastLoginAt, this._initializeTime();
  }
  toJSON() {
    return {
      createdAt: this.createdAt,
      lastLoginAt: this.lastLoginAt
    };
  }
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
async function Ne(t) {
  var e;
  const r = t.auth, n = await t.getIdToken(), i = await We(t, Kt(r, { idToken: n }));
  y(
    i == null ? void 0 : i.users.length,
    r,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  );
  const s = i.users[0];
  t._notifyReloadListener(s);
  const a = !((e = s.providerUserInfo) === null || e === void 0) && e.length ? zt(s.providerUserInfo) : [], c = ri(t.providerData, a), o = t.isAnonymous, u = !(t.email && s.passwordHash) && !(c != null && c.length), _ = o ? u : !1, f = {
    uid: s.localId,
    displayName: s.displayName || null,
    photoURL: s.photoUrl || null,
    email: s.email || null,
    emailVerified: s.emailVerified || !1,
    phoneNumber: s.phoneNumber || null,
    tenantId: s.tenantId || null,
    providerData: c,
    metadata: new Ke(s.createdAt, s.lastLoginAt),
    isAnonymous: _
  };
  Object.assign(t, f);
}
async function ti(t) {
  const e = Ce(t);
  await Ne(e), await e.auth._persistUserIfCurrent(e), e.auth._notifyListenersIfCurrent(e);
}
function ri(t, e) {
  return [...t.filter((n) => !e.some((i) => i.providerId === n.providerId)), ...e];
}
function zt(t) {
  return t.map((e) => {
    var { providerId: r } = e, n = $t(e, ["providerId"]);
    return {
      providerId: r,
      uid: n.rawId || "",
      displayName: n.displayName || null,
      email: n.email || null,
      phoneNumber: n.phoneNumber || null,
      photoURL: n.photoUrl || null
    };
  });
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
async function ni(t, e) {
  const r = await Gt(t, {}, async () => {
    const n = Rt({
      grant_type: "refresh_token",
      refresh_token: e
    }).slice(1), { tokenApiHost: i, apiKey: s } = t.config, a = Wt(t, i, "/v1/token", `key=${s}`), c = await t._getAdditionalHeaders();
    return c[
      "Content-Type"
      /* HttpHeader.CONTENT_TYPE */
    ] = "application/x-www-form-urlencoded", Ht.fetch()(a, {
      method: "POST",
      headers: c,
      body: n
    });
  });
  return {
    accessToken: r.access_token,
    expiresIn: r.expires_in,
    refreshToken: r.refresh_token
  };
}
async function ii(t, e) {
  return De(t, "POST", "/v2/accounts:revokeToken", jt(t, e));
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class se {
  constructor() {
    this.refreshToken = null, this.accessToken = null, this.expirationTime = null;
  }
  get isExpired() {
    return !this.expirationTime || Date.now() > this.expirationTime - 3e4;
  }
  updateFromServerResponse(e) {
    y(
      e.idToken,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), y(
      typeof e.idToken < "u",
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), y(
      typeof e.refreshToken < "u",
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const r = "expiresIn" in e && typeof e.expiresIn < "u" ? Number(e.expiresIn) : lt(e.idToken);
    this.updateTokensAndExpiration(e.idToken, e.refreshToken, r);
  }
  updateFromIdToken(e) {
    y(
      e.length !== 0,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const r = lt(e);
    this.updateTokensAndExpiration(e, null, r);
  }
  async getToken(e, r = !1) {
    return !r && this.accessToken && !this.isExpired ? this.accessToken : (y(
      this.refreshToken,
      e,
      "user-token-expired"
      /* AuthErrorCode.TOKEN_EXPIRED */
    ), this.refreshToken ? (await this.refresh(e, this.refreshToken), this.accessToken) : null);
  }
  clearRefreshToken() {
    this.refreshToken = null;
  }
  async refresh(e, r) {
    const { accessToken: n, refreshToken: i, expiresIn: s } = await ni(e, r);
    this.updateTokensAndExpiration(n, i, Number(s));
  }
  updateTokensAndExpiration(e, r, n) {
    this.refreshToken = r || null, this.accessToken = e || null, this.expirationTime = Date.now() + n * 1e3;
  }
  static fromJSON(e, r) {
    const { refreshToken: n, accessToken: i, expirationTime: s } = r, a = new se();
    return n && (y(typeof n == "string", "internal-error", {
      appName: e
    }), a.refreshToken = n), i && (y(typeof i == "string", "internal-error", {
      appName: e
    }), a.accessToken = i), s && (y(typeof s == "number", "internal-error", {
      appName: e
    }), a.expirationTime = s), a;
  }
  toJSON() {
    return {
      refreshToken: this.refreshToken,
      accessToken: this.accessToken,
      expirationTime: this.expirationTime
    };
  }
  _assign(e) {
    this.accessToken = e.accessToken, this.refreshToken = e.refreshToken, this.expirationTime = e.expirationTime;
  }
  _clone() {
    return Object.assign(new se(), this.toJSON());
  }
  _performRefresh() {
    return fe("not implemented");
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function K(t, e) {
  y(typeof t == "string" || typeof t > "u", "internal-error", { appName: e });
}
class Y {
  constructor(e) {
    var { uid: r, auth: n, stsTokenManager: i } = e, s = $t(e, ["uid", "auth", "stsTokenManager"]);
    this.providerId = "firebase", this.proactiveRefresh = new ei(this), this.reloadUserInfo = null, this.reloadListener = null, this.uid = r, this.auth = n, this.stsTokenManager = i, this.accessToken = i.accessToken, this.displayName = s.displayName || null, this.email = s.email || null, this.emailVerified = s.emailVerified || !1, this.phoneNumber = s.phoneNumber || null, this.photoURL = s.photoURL || null, this.isAnonymous = s.isAnonymous || !1, this.tenantId = s.tenantId || null, this.providerData = s.providerData ? [...s.providerData] : [], this.metadata = new Ke(s.createdAt || void 0, s.lastLoginAt || void 0);
  }
  async getIdToken(e) {
    const r = await We(this, this.stsTokenManager.getToken(this.auth, e));
    return y(
      r,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.accessToken !== r && (this.accessToken = r, await this.auth._persistUserIfCurrent(this), this.auth._notifyListenersIfCurrent(this)), r;
  }
  getIdTokenResult(e) {
    return Qn(this, e);
  }
  reload() {
    return ti(this);
  }
  _assign(e) {
    this !== e && (y(
      this.uid === e.uid,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.displayName = e.displayName, this.photoURL = e.photoURL, this.email = e.email, this.emailVerified = e.emailVerified, this.phoneNumber = e.phoneNumber, this.isAnonymous = e.isAnonymous, this.tenantId = e.tenantId, this.providerData = e.providerData.map((r) => Object.assign({}, r)), this.metadata._copy(e.metadata), this.stsTokenManager._assign(e.stsTokenManager));
  }
  _clone(e) {
    const r = new Y(Object.assign(Object.assign({}, this), { auth: e, stsTokenManager: this.stsTokenManager._clone() }));
    return r.metadata._copy(this.metadata), r;
  }
  _onReload(e) {
    y(
      !this.reloadListener,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.reloadListener = e, this.reloadUserInfo && (this._notifyReloadListener(this.reloadUserInfo), this.reloadUserInfo = null);
  }
  _notifyReloadListener(e) {
    this.reloadListener ? this.reloadListener(e) : this.reloadUserInfo = e;
  }
  _startProactiveRefresh() {
    this.proactiveRefresh._start();
  }
  _stopProactiveRefresh() {
    this.proactiveRefresh._stop();
  }
  async _updateTokensIfNecessary(e, r = !1) {
    let n = !1;
    e.idToken && e.idToken !== this.stsTokenManager.accessToken && (this.stsTokenManager.updateFromServerResponse(e), n = !0), r && await Ne(this), await this.auth._persistUserIfCurrent(this), n && this.auth._notifyListenersIfCurrent(this);
  }
  async delete() {
    if (de(this.auth.app))
      return Promise.reject(Ae(this.auth));
    const e = await this.getIdToken();
    return await We(this, Xn(this.auth, { idToken: e })), this.stsTokenManager.clearRefreshToken(), this.auth.signOut();
  }
  toJSON() {
    return Object.assign(Object.assign({
      uid: this.uid,
      email: this.email || void 0,
      emailVerified: this.emailVerified,
      displayName: this.displayName || void 0,
      isAnonymous: this.isAnonymous,
      photoURL: this.photoURL || void 0,
      phoneNumber: this.phoneNumber || void 0,
      tenantId: this.tenantId || void 0,
      providerData: this.providerData.map((e) => Object.assign({}, e)),
      stsTokenManager: this.stsTokenManager.toJSON(),
      // Redirect event ID must be maintained in case there is a pending
      // redirect event.
      _redirectEventId: this._redirectEventId
    }, this.metadata.toJSON()), {
      // Required for compatibility with the legacy SDK (go/firebase-auth-sdk-persistence-parsing):
      apiKey: this.auth.config.apiKey,
      appName: this.auth.name
    });
  }
  get refreshToken() {
    return this.stsTokenManager.refreshToken || "";
  }
  static _fromJSON(e, r) {
    var n, i, s, a, c, o, u, _;
    const f = (n = r.displayName) !== null && n !== void 0 ? n : void 0, A = (i = r.email) !== null && i !== void 0 ? i : void 0, I = (s = r.phoneNumber) !== null && s !== void 0 ? s : void 0, T = (a = r.photoURL) !== null && a !== void 0 ? a : void 0, O = (c = r.tenantId) !== null && c !== void 0 ? c : void 0, $ = (o = r._redirectEventId) !== null && o !== void 0 ? o : void 0, P = (u = r.createdAt) !== null && u !== void 0 ? u : void 0, j = (_ = r.lastLoginAt) !== null && _ !== void 0 ? _ : void 0, { uid: B, emailVerified: X, isAnonymous: x, providerData: L, stsTokenManager: G } = r;
    y(
      B && G,
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const H = se.fromJSON(this.name, G);
    y(
      typeof B == "string",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), K(f, e.name), K(A, e.name), y(
      typeof X == "boolean",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), y(
      typeof x == "boolean",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), K(I, e.name), K(T, e.name), K(O, e.name), K($, e.name), K(P, e.name), K(j, e.name);
    const V = new Y({
      uid: B,
      auth: e,
      email: A,
      emailVerified: X,
      displayName: f,
      isAnonymous: x,
      photoURL: T,
      phoneNumber: I,
      tenantId: O,
      stsTokenManager: H,
      createdAt: P,
      lastLoginAt: j
    });
    return L && Array.isArray(L) && (V.providerData = L.map((N) => Object.assign({}, N))), $ && (V._redirectEventId = $), V;
  }
  /**
   * Initialize a User from an idToken server response
   * @param auth
   * @param idTokenResponse
   */
  static async _fromIdTokenResponse(e, r, n = !1) {
    const i = new se();
    i.updateFromServerResponse(r);
    const s = new Y({
      uid: r.localId,
      auth: e,
      stsTokenManager: i,
      isAnonymous: n
    });
    return await Ne(s), s;
  }
  /**
   * Initialize a User from an idToken server response
   * @param auth
   * @param idTokenResponse
   */
  static async _fromGetAccountInfoResponse(e, r, n) {
    const i = r.users[0];
    y(
      i.localId !== void 0,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const s = i.providerUserInfo !== void 0 ? zt(i.providerUserInfo) : [], a = !(i.email && i.passwordHash) && !(s != null && s.length), c = new se();
    c.updateFromIdToken(n);
    const o = new Y({
      uid: i.localId,
      auth: e,
      stsTokenManager: c,
      isAnonymous: a
    }), u = {
      uid: i.localId,
      displayName: i.displayName || null,
      photoURL: i.photoUrl || null,
      email: i.email || null,
      emailVerified: i.emailVerified || !1,
      phoneNumber: i.phoneNumber || null,
      tenantId: i.tenantId || null,
      providerData: s,
      metadata: new Ke(i.createdAt, i.lastLoginAt),
      isAnonymous: !(i.email && i.passwordHash) && !(s != null && s.length)
    };
    return Object.assign(o, u), o;
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const dt = /* @__PURE__ */ new Map();
function ee(t) {
  we(t instanceof Function, "Expected a class definition");
  let e = dt.get(t);
  return e ? (we(e instanceof t, "Instance stored in cache mismatched with class"), e) : (e = new t(), dt.set(t, e), e);
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class qt {
  constructor() {
    this.type = "NONE", this.storage = {};
  }
  async _isAvailable() {
    return !0;
  }
  async _set(e, r) {
    this.storage[e] = r;
  }
  async _get(e) {
    const r = this.storage[e];
    return r === void 0 ? null : r;
  }
  async _remove(e) {
    delete this.storage[e];
  }
  _addListener(e, r) {
  }
  _removeListener(e, r) {
  }
}
qt.type = "NONE";
const ht = qt;
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Ve(t, e, r) {
  return `firebase:${t}:${e}:${r}`;
}
class ae {
  constructor(e, r, n) {
    this.persistence = e, this.auth = r, this.userKey = n;
    const { config: i, name: s } = this.auth;
    this.fullUserKey = Ve(this.userKey, i.apiKey, s), this.fullPersistenceKey = Ve("persistence", i.apiKey, s), this.boundEventHandler = r._onStorageEvent.bind(r), this.persistence._addListener(this.fullUserKey, this.boundEventHandler);
  }
  setCurrentUser(e) {
    return this.persistence._set(this.fullUserKey, e.toJSON());
  }
  async getCurrentUser() {
    const e = await this.persistence._get(this.fullUserKey);
    return e ? Y._fromJSON(this.auth, e) : null;
  }
  removeCurrentUser() {
    return this.persistence._remove(this.fullUserKey);
  }
  savePersistenceForRedirect() {
    return this.persistence._set(this.fullPersistenceKey, this.persistence.type);
  }
  async setPersistence(e) {
    if (this.persistence === e)
      return;
    const r = await this.getCurrentUser();
    if (await this.removeCurrentUser(), this.persistence = e, r)
      return this.setCurrentUser(r);
  }
  delete() {
    this.persistence._removeListener(this.fullUserKey, this.boundEventHandler);
  }
  static async create(e, r, n = "authUser") {
    if (!r.length)
      return new ae(ee(ht), e, n);
    const i = (await Promise.all(r.map(async (u) => {
      if (await u._isAvailable())
        return u;
    }))).filter((u) => u);
    let s = i[0] || ee(ht);
    const a = Ve(n, e.config.apiKey, e.name);
    let c = null;
    for (const u of r)
      try {
        const _ = await u._get(a);
        if (_) {
          const f = Y._fromJSON(e, _);
          u !== s && (c = f), s = u;
          break;
        }
      } catch {
      }
    const o = i.filter((u) => u._shouldAllowMigration);
    return !s._shouldAllowMigration || !o.length ? new ae(s, e, n) : (s = o[0], c && await s._set(a, c.toJSON()), await Promise.all(r.map(async (u) => {
      if (u !== s)
        try {
          await u._remove(a);
        } catch {
        }
    })), new ae(s, e, n));
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function ft(t) {
  const e = t.toLowerCase();
  if (e.includes("opera/") || e.includes("opr/") || e.includes("opios/"))
    return "Opera";
  if (ci(e))
    return "IEMobile";
  if (e.includes("msie") || e.includes("trident/"))
    return "IE";
  if (e.includes("edge/"))
    return "Edge";
  if (si(e))
    return "Firefox";
  if (e.includes("silk/"))
    return "Silk";
  if (li(e))
    return "Blackberry";
  if (di(e))
    return "Webos";
  if (ai(e))
    return "Safari";
  if ((e.includes("chrome/") || oi(e)) && !e.includes("edge/"))
    return "Chrome";
  if (ui(e))
    return "Android";
  {
    const r = /([a-zA-Z\d\.]+)\/[a-zA-Z\d\.]*$/, n = t.match(r);
    if ((n == null ? void 0 : n.length) === 2)
      return n[1];
  }
  return "Other";
}
function si(t = F()) {
  return /firefox\//i.test(t);
}
function ai(t = F()) {
  const e = t.toLowerCase();
  return e.includes("safari/") && !e.includes("chrome/") && !e.includes("crios/") && !e.includes("android");
}
function oi(t = F()) {
  return /crios\//i.test(t);
}
function ci(t = F()) {
  return /iemobile/i.test(t);
}
function ui(t = F()) {
  return /android/i.test(t);
}
function li(t = F()) {
  return /blackberry/i.test(t);
}
function di(t = F()) {
  return /webos/i.test(t);
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Jt(t, e = []) {
  let r;
  switch (t) {
    case "Browser":
      r = ft(F());
      break;
    case "Worker":
      r = `${ft(F())}-${t}`;
      break;
    default:
      r = t;
  }
  const n = e.length ? e.join(",") : "FirebaseCore-web";
  return `${r}/JsCore/${Je}/${n}`;
}
/**
 * @license
 * Copyright 2022 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class hi {
  constructor(e) {
    this.auth = e, this.queue = [];
  }
  pushCallback(e, r) {
    const n = (s) => new Promise((a, c) => {
      try {
        const o = e(s);
        a(o);
      } catch (o) {
        c(o);
      }
    });
    n.onAbort = r, this.queue.push(n);
    const i = this.queue.length - 1;
    return () => {
      this.queue[i] = () => Promise.resolve();
    };
  }
  async runMiddleware(e) {
    if (this.auth.currentUser === e)
      return;
    const r = [];
    try {
      for (const n of this.queue)
        await n(e), n.onAbort && r.push(n.onAbort);
    } catch (n) {
      r.reverse();
      for (const i of r)
        try {
          i();
        } catch {
        }
      throw this.auth._errorFactory.create("login-blocked", {
        originalMessage: n == null ? void 0 : n.message
      });
    }
  }
}
/**
 * @license
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
async function fi(t, e = {}) {
  return De(t, "GET", "/v2/passwordPolicy", jt(t, e));
}
/**
 * @license
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const pi = 6;
class _i {
  constructor(e) {
    var r, n, i, s;
    const a = e.customStrengthOptions;
    this.customStrengthOptions = {}, this.customStrengthOptions.minPasswordLength = (r = a.minPasswordLength) !== null && r !== void 0 ? r : pi, a.maxPasswordLength && (this.customStrengthOptions.maxPasswordLength = a.maxPasswordLength), a.containsLowercaseCharacter !== void 0 && (this.customStrengthOptions.containsLowercaseLetter = a.containsLowercaseCharacter), a.containsUppercaseCharacter !== void 0 && (this.customStrengthOptions.containsUppercaseLetter = a.containsUppercaseCharacter), a.containsNumericCharacter !== void 0 && (this.customStrengthOptions.containsNumericCharacter = a.containsNumericCharacter), a.containsNonAlphanumericCharacter !== void 0 && (this.customStrengthOptions.containsNonAlphanumericCharacter = a.containsNonAlphanumericCharacter), this.enforcementState = e.enforcementState, this.enforcementState === "ENFORCEMENT_STATE_UNSPECIFIED" && (this.enforcementState = "OFF"), this.allowedNonAlphanumericCharacters = (i = (n = e.allowedNonAlphanumericCharacters) === null || n === void 0 ? void 0 : n.join("")) !== null && i !== void 0 ? i : "", this.forceUpgradeOnSignin = (s = e.forceUpgradeOnSignin) !== null && s !== void 0 ? s : !1, this.schemaVersion = e.schemaVersion;
  }
  validatePassword(e) {
    var r, n, i, s, a, c;
    const o = {
      isValid: !0,
      passwordPolicy: this
    };
    return this.validatePasswordLengthOptions(e, o), this.validatePasswordCharacterOptions(e, o), o.isValid && (o.isValid = (r = o.meetsMinPasswordLength) !== null && r !== void 0 ? r : !0), o.isValid && (o.isValid = (n = o.meetsMaxPasswordLength) !== null && n !== void 0 ? n : !0), o.isValid && (o.isValid = (i = o.containsLowercaseLetter) !== null && i !== void 0 ? i : !0), o.isValid && (o.isValid = (s = o.containsUppercaseLetter) !== null && s !== void 0 ? s : !0), o.isValid && (o.isValid = (a = o.containsNumericCharacter) !== null && a !== void 0 ? a : !0), o.isValid && (o.isValid = (c = o.containsNonAlphanumericCharacter) !== null && c !== void 0 ? c : !0), o;
  }
  /**
   * Validates that the password meets the length options for the policy.
   *
   * @param password Password to validate.
   * @param status Validation status.
   */
  validatePasswordLengthOptions(e, r) {
    const n = this.customStrengthOptions.minPasswordLength, i = this.customStrengthOptions.maxPasswordLength;
    n && (r.meetsMinPasswordLength = e.length >= n), i && (r.meetsMaxPasswordLength = e.length <= i);
  }
  /**
   * Validates that the password meets the character options for the policy.
   *
   * @param password Password to validate.
   * @param status Validation status.
   */
  validatePasswordCharacterOptions(e, r) {
    this.updatePasswordCharacterOptionsStatuses(
      r,
      /* containsLowercaseCharacter= */
      !1,
      /* containsUppercaseCharacter= */
      !1,
      /* containsNumericCharacter= */
      !1,
      /* containsNonAlphanumericCharacter= */
      !1
    );
    let n;
    for (let i = 0; i < e.length; i++)
      n = e.charAt(i), this.updatePasswordCharacterOptionsStatuses(
        r,
        /* containsLowercaseCharacter= */
        n >= "a" && n <= "z",
        /* containsUppercaseCharacter= */
        n >= "A" && n <= "Z",
        /* containsNumericCharacter= */
        n >= "0" && n <= "9",
        /* containsNonAlphanumericCharacter= */
        this.allowedNonAlphanumericCharacters.includes(n)
      );
  }
  /**
   * Updates the running validation status with the statuses for the character options.
   * Expected to be called each time a character is processed to update each option status
   * based on the current character.
   *
   * @param status Validation status.
   * @param containsLowercaseCharacter Whether the character is a lowercase letter.
   * @param containsUppercaseCharacter Whether the character is an uppercase letter.
   * @param containsNumericCharacter Whether the character is a numeric character.
   * @param containsNonAlphanumericCharacter Whether the character is a non-alphanumeric character.
   */
  updatePasswordCharacterOptionsStatuses(e, r, n, i, s) {
    this.customStrengthOptions.containsLowercaseLetter && (e.containsLowercaseLetter || (e.containsLowercaseLetter = r)), this.customStrengthOptions.containsUppercaseLetter && (e.containsUppercaseLetter || (e.containsUppercaseLetter = n)), this.customStrengthOptions.containsNumericCharacter && (e.containsNumericCharacter || (e.containsNumericCharacter = i)), this.customStrengthOptions.containsNonAlphanumericCharacter && (e.containsNonAlphanumericCharacter || (e.containsNonAlphanumericCharacter = s));
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class mi {
  constructor(e, r, n, i) {
    this.app = e, this.heartbeatServiceProvider = r, this.appCheckServiceProvider = n, this.config = i, this.currentUser = null, this.emulatorConfig = null, this.operations = Promise.resolve(), this.authStateSubscription = new pt(this), this.idTokenSubscription = new pt(this), this.beforeStateQueue = new hi(this), this.redirectUser = null, this.isProactiveRefreshEnabled = !1, this.EXPECTED_PASSWORD_POLICY_SCHEMA_VERSION = 1, this._canInitEmulator = !0, this._isInitialized = !1, this._deleted = !1, this._initializationPromise = null, this._popupRedirectResolver = null, this._errorFactory = Vt, this._agentRecaptchaConfig = null, this._tenantRecaptchaConfigs = {}, this._projectPasswordPolicy = null, this._tenantPasswordPolicies = {}, this.lastNotifiedUid = void 0, this.languageCode = null, this.tenantId = null, this.settings = { appVerificationDisabledForTesting: !1 }, this.frameworks = [], this.name = e.name, this.clientVersion = i.sdkClientVersion;
  }
  _initializeWithPersistence(e, r) {
    return r && (this._popupRedirectResolver = ee(r)), this._initializationPromise = this.queue(async () => {
      var n, i;
      if (!this._deleted && (this.persistenceManager = await ae.create(this, e), !this._deleted)) {
        if (!((n = this._popupRedirectResolver) === null || n === void 0) && n._shouldInitProactively)
          try {
            await this._popupRedirectResolver._initialize(this);
          } catch {
          }
        await this.initializeCurrentUser(r), this.lastNotifiedUid = ((i = this.currentUser) === null || i === void 0 ? void 0 : i.uid) || null, !this._deleted && (this._isInitialized = !0);
      }
    }), this._initializationPromise;
  }
  /**
   * If the persistence is changed in another window, the user manager will let us know
   */
  async _onStorageEvent() {
    if (this._deleted)
      return;
    const e = await this.assertedPersistence.getCurrentUser();
    if (!(!this.currentUser && !e)) {
      if (this.currentUser && e && this.currentUser.uid === e.uid) {
        this._currentUser._assign(e), await this.currentUser.getIdToken();
        return;
      }
      await this._updateCurrentUser(
        e,
        /* skipBeforeStateCallbacks */
        !0
      );
    }
  }
  async initializeCurrentUserFromIdToken(e) {
    try {
      const r = await Kt(this, { idToken: e }), n = await Y._fromGetAccountInfoResponse(this, r, e);
      await this.directlySetCurrentUser(n);
    } catch (r) {
      console.warn("FirebaseServerApp could not login user with provided authIdToken: ", r), await this.directlySetCurrentUser(null);
    }
  }
  async initializeCurrentUser(e) {
    var r;
    if (de(this.app)) {
      const a = this.app.settings.authIdToken;
      return a ? new Promise((c) => {
        setTimeout(() => this.initializeCurrentUserFromIdToken(a).then(c, c));
      }) : this.directlySetCurrentUser(null);
    }
    const n = await this.assertedPersistence.getCurrentUser();
    let i = n, s = !1;
    if (e && this.config.authDomain) {
      await this.getOrInitRedirectPersistenceManager();
      const a = (r = this.redirectUser) === null || r === void 0 ? void 0 : r._redirectEventId, c = i == null ? void 0 : i._redirectEventId, o = await this.tryRedirectSignIn(e);
      (!a || a === c) && (o != null && o.user) && (i = o.user, s = !0);
    }
    if (!i)
      return this.directlySetCurrentUser(null);
    if (!i._redirectEventId) {
      if (s)
        try {
          await this.beforeStateQueue.runMiddleware(i);
        } catch (a) {
          i = n, this._popupRedirectResolver._overrideRedirectResult(this, () => Promise.reject(a));
        }
      return i ? this.reloadAndSetCurrentUserOrClear(i) : this.directlySetCurrentUser(null);
    }
    return y(
      this._popupRedirectResolver,
      this,
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), await this.getOrInitRedirectPersistenceManager(), this.redirectUser && this.redirectUser._redirectEventId === i._redirectEventId ? this.directlySetCurrentUser(i) : this.reloadAndSetCurrentUserOrClear(i);
  }
  async tryRedirectSignIn(e) {
    let r = null;
    try {
      r = await this._popupRedirectResolver._completeRedirectFn(this, e, !0);
    } catch {
      await this._setRedirectUser(null);
    }
    return r;
  }
  async reloadAndSetCurrentUserOrClear(e) {
    try {
      await Ne(e);
    } catch (r) {
      if ((r == null ? void 0 : r.code) !== "auth/network-request-failed")
        return this.directlySetCurrentUser(null);
    }
    return this.directlySetCurrentUser(e);
  }
  useDeviceLanguage() {
    this.languageCode = Kn();
  }
  async _delete() {
    this._deleted = !0;
  }
  async updateCurrentUser(e) {
    if (de(this.app))
      return Promise.reject(Ae(this));
    const r = e ? Ce(e) : null;
    return r && y(
      r.auth.config.apiKey === this.config.apiKey,
      this,
      "invalid-user-token"
      /* AuthErrorCode.INVALID_AUTH */
    ), this._updateCurrentUser(r && r._clone(this));
  }
  async _updateCurrentUser(e, r = !1) {
    if (!this._deleted)
      return e && y(
        this.tenantId === e.tenantId,
        this,
        "tenant-id-mismatch"
        /* AuthErrorCode.TENANT_ID_MISMATCH */
      ), r || await this.beforeStateQueue.runMiddleware(e), this.queue(async () => {
        await this.directlySetCurrentUser(e), this.notifyAuthListeners();
      });
  }
  async signOut() {
    return de(this.app) ? Promise.reject(Ae(this)) : (await this.beforeStateQueue.runMiddleware(null), (this.redirectPersistenceManager || this._popupRedirectResolver) && await this._setRedirectUser(null), this._updateCurrentUser(
      null,
      /* skipBeforeStateCallbacks */
      !0
    ));
  }
  setPersistence(e) {
    return de(this.app) ? Promise.reject(Ae(this)) : this.queue(async () => {
      await this.assertedPersistence.setPersistence(ee(e));
    });
  }
  _getRecaptchaConfig() {
    return this.tenantId == null ? this._agentRecaptchaConfig : this._tenantRecaptchaConfigs[this.tenantId];
  }
  async validatePassword(e) {
    this._getPasswordPolicyInternal() || await this._updatePasswordPolicy();
    const r = this._getPasswordPolicyInternal();
    return r.schemaVersion !== this.EXPECTED_PASSWORD_POLICY_SCHEMA_VERSION ? Promise.reject(this._errorFactory.create("unsupported-password-policy-schema-version", {})) : r.validatePassword(e);
  }
  _getPasswordPolicyInternal() {
    return this.tenantId === null ? this._projectPasswordPolicy : this._tenantPasswordPolicies[this.tenantId];
  }
  async _updatePasswordPolicy() {
    const e = await fi(this), r = new _i(e);
    this.tenantId === null ? this._projectPasswordPolicy = r : this._tenantPasswordPolicies[this.tenantId] = r;
  }
  _getPersistence() {
    return this.assertedPersistence.persistence.type;
  }
  _updateErrorMap(e) {
    this._errorFactory = new Ee("auth", "Firebase", e());
  }
  onAuthStateChanged(e, r, n) {
    return this.registerStateListener(this.authStateSubscription, e, r, n);
  }
  beforeAuthStateChanged(e, r) {
    return this.beforeStateQueue.pushCallback(e, r);
  }
  onIdTokenChanged(e, r, n) {
    return this.registerStateListener(this.idTokenSubscription, e, r, n);
  }
  authStateReady() {
    return new Promise((e, r) => {
      if (this.currentUser)
        e();
      else {
        const n = this.onAuthStateChanged(() => {
          n(), e();
        }, r);
      }
    });
  }
  /**
   * Revokes the given access token. Currently only supports Apple OAuth access tokens.
   */
  async revokeAccessToken(e) {
    if (this.currentUser) {
      const r = await this.currentUser.getIdToken(), n = {
        providerId: "apple.com",
        tokenType: "ACCESS_TOKEN",
        token: e,
        idToken: r
      };
      this.tenantId != null && (n.tenantId = this.tenantId), await ii(this, n);
    }
  }
  toJSON() {
    var e;
    return {
      apiKey: this.config.apiKey,
      authDomain: this.config.authDomain,
      appName: this.name,
      currentUser: (e = this._currentUser) === null || e === void 0 ? void 0 : e.toJSON()
    };
  }
  async _setRedirectUser(e, r) {
    const n = await this.getOrInitRedirectPersistenceManager(r);
    return e === null ? n.removeCurrentUser() : n.setCurrentUser(e);
  }
  async getOrInitRedirectPersistenceManager(e) {
    if (!this.redirectPersistenceManager) {
      const r = e && ee(e) || this._popupRedirectResolver;
      y(
        r,
        this,
        "argument-error"
        /* AuthErrorCode.ARGUMENT_ERROR */
      ), this.redirectPersistenceManager = await ae.create(
        this,
        [ee(r._redirectPersistence)],
        "redirectUser"
        /* KeyName.REDIRECT_USER */
      ), this.redirectUser = await this.redirectPersistenceManager.getCurrentUser();
    }
    return this.redirectPersistenceManager;
  }
  async _redirectUserForId(e) {
    var r, n;
    return this._isInitialized && await this.queue(async () => {
    }), ((r = this._currentUser) === null || r === void 0 ? void 0 : r._redirectEventId) === e ? this._currentUser : ((n = this.redirectUser) === null || n === void 0 ? void 0 : n._redirectEventId) === e ? this.redirectUser : null;
  }
  async _persistUserIfCurrent(e) {
    if (e === this.currentUser)
      return this.queue(async () => this.directlySetCurrentUser(e));
  }
  /** Notifies listeners only if the user is current */
  _notifyListenersIfCurrent(e) {
    e === this.currentUser && this.notifyAuthListeners();
  }
  _key() {
    return `${this.config.authDomain}:${this.config.apiKey}:${this.name}`;
  }
  _startProactiveRefresh() {
    this.isProactiveRefreshEnabled = !0, this.currentUser && this._currentUser._startProactiveRefresh();
  }
  _stopProactiveRefresh() {
    this.isProactiveRefreshEnabled = !1, this.currentUser && this._currentUser._stopProactiveRefresh();
  }
  /** Returns the current user cast as the internal type */
  get _currentUser() {
    return this.currentUser;
  }
  notifyAuthListeners() {
    var e, r;
    if (!this._isInitialized)
      return;
    this.idTokenSubscription.next(this.currentUser);
    const n = (r = (e = this.currentUser) === null || e === void 0 ? void 0 : e.uid) !== null && r !== void 0 ? r : null;
    this.lastNotifiedUid !== n && (this.lastNotifiedUid = n, this.authStateSubscription.next(this.currentUser));
  }
  registerStateListener(e, r, n, i) {
    if (this._deleted)
      return () => {
      };
    const s = typeof r == "function" ? r : r.next.bind(r);
    let a = !1;
    const c = this._isInitialized ? Promise.resolve() : this._initializationPromise;
    if (y(
      c,
      this,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), c.then(() => {
      a || s(this.currentUser);
    }), typeof r == "function") {
      const o = e.addObserver(r, n, i);
      return () => {
        a = !0, o();
      };
    } else {
      const o = e.addObserver(r);
      return () => {
        a = !0, o();
      };
    }
  }
  /**
   * Unprotected (from race conditions) method to set the current user. This
   * should only be called from within a queued callback. This is necessary
   * because the queue shouldn't rely on another queued callback.
   */
  async directlySetCurrentUser(e) {
    this.currentUser && this.currentUser !== e && this._currentUser._stopProactiveRefresh(), e && this.isProactiveRefreshEnabled && e._startProactiveRefresh(), this.currentUser = e, e ? await this.assertedPersistence.setCurrentUser(e) : await this.assertedPersistence.removeCurrentUser();
  }
  queue(e) {
    return this.operations = this.operations.then(e, e), this.operations;
  }
  get assertedPersistence() {
    return y(
      this.persistenceManager,
      this,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.persistenceManager;
  }
  _logFramework(e) {
    !e || this.frameworks.includes(e) || (this.frameworks.push(e), this.frameworks.sort(), this.clientVersion = Jt(this.config.clientPlatform, this._getFrameworks()));
  }
  _getFrameworks() {
    return this.frameworks;
  }
  async _getAdditionalHeaders() {
    var e;
    const r = {
      "X-Client-Version": this.clientVersion
    };
    this.app.options.appId && (r[
      "X-Firebase-gmpid"
      /* HttpHeader.X_FIREBASE_GMPID */
    ] = this.app.options.appId);
    const n = await ((e = this.heartbeatServiceProvider.getImmediate({
      optional: !0
    })) === null || e === void 0 ? void 0 : e.getHeartbeatsHeader());
    n && (r[
      "X-Firebase-Client"
      /* HttpHeader.X_FIREBASE_CLIENT */
    ] = n);
    const i = await this._getAppCheckToken();
    return i && (r[
      "X-Firebase-AppCheck"
      /* HttpHeader.X_FIREBASE_APP_CHECK */
    ] = i), r;
  }
  async _getAppCheckToken() {
    var e;
    const r = await ((e = this.appCheckServiceProvider.getImmediate({ optional: !0 })) === null || e === void 0 ? void 0 : e.getToken());
    return r != null && r.error && jn(`Error while retrieving App Check token: ${r.error}`), r == null ? void 0 : r.token;
  }
}
function gi(t) {
  return Ce(t);
}
class pt {
  constructor(e) {
    this.auth = e, this.observer = null, this.addObserver = kr((r) => this.observer = r);
  }
  get next() {
    return y(
      this.observer,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.observer.next.bind(this.observer);
  }
}
function Ei(t, e) {
  const r = (e == null ? void 0 : e.persistence) || [], n = (Array.isArray(r) ? r : [r]).map(ee);
  e != null && e.errorMap && t._updateErrorMap(e.errorMap), t._initializeWithPersistence(n, e == null ? void 0 : e.popupRedirectResolver);
}
new Ie(3e4, 6e4);
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
new Ie(2e3, 1e4);
/**
 * @license
 * Copyright 2020 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
new Ie(3e4, 6e4);
/**
 * @license
 * Copyright 2020 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
new Ie(5e3, 15e3);
var _t = "@firebase/auth", mt = "1.7.4";
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Ii {
  constructor(e) {
    this.auth = e, this.internalListeners = /* @__PURE__ */ new Map();
  }
  getUid() {
    var e;
    return this.assertAuthConfigured(), ((e = this.auth.currentUser) === null || e === void 0 ? void 0 : e.uid) || null;
  }
  async getToken(e) {
    return this.assertAuthConfigured(), await this.auth._initializationPromise, this.auth.currentUser ? { accessToken: await this.auth.currentUser.getIdToken(e) } : null;
  }
  addAuthTokenListener(e) {
    if (this.assertAuthConfigured(), this.internalListeners.has(e))
      return;
    const r = this.auth.onIdTokenChanged((n) => {
      e((n == null ? void 0 : n.stsTokenManager.accessToken) || null);
    });
    this.internalListeners.set(e, r), this.updateProactiveRefresh();
  }
  removeAuthTokenListener(e) {
    this.assertAuthConfigured();
    const r = this.internalListeners.get(e);
    r && (this.internalListeners.delete(e), r(), this.updateProactiveRefresh());
  }
  assertAuthConfigured() {
    y(
      this.auth._initializationPromise,
      "dependent-sdk-initialized-before-auth"
      /* AuthErrorCode.DEPENDENT_SDK_INIT_BEFORE_AUTH */
    );
  }
  updateProactiveRefresh() {
    this.internalListeners.size > 0 ? this.auth._startProactiveRefresh() : this.auth._stopProactiveRefresh();
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function yi(t) {
  switch (t) {
    case "Node":
      return "node";
    case "ReactNative":
      return "rn";
    case "Worker":
      return "webworker";
    case "Cordova":
      return "cordova";
    case "WebExtension":
      return "web-extension";
    default:
      return;
  }
}
function vi(t) {
  me(new _e(
    "auth",
    (e, { options: r }) => {
      const n = e.getProvider("app").getImmediate(), i = e.getProvider("heartbeat"), s = e.getProvider("app-check-internal"), { apiKey: a, authDomain: c } = n.options;
      y(a && !a.includes(":"), "invalid-api-key", { appName: n.name });
      const o = {
        apiKey: a,
        authDomain: c,
        clientPlatform: t,
        apiHost: "identitytoolkit.googleapis.com",
        tokenApiHost: "securetoken.googleapis.com",
        apiScheme: "https",
        sdkClientVersion: Jt(t)
      }, u = new mi(n, i, s, o);
      return Ei(u, r), u;
    },
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setInstantiationMode(
    "EXPLICIT"
    /* InstantiationMode.EXPLICIT */
  ).setInstanceCreatedCallback((e, r, n) => {
    e.getProvider(
      "auth-internal"
      /* _ComponentName.AUTH_INTERNAL */
    ).initialize();
  })), me(new _e(
    "auth-internal",
    (e) => {
      const r = gi(e.getProvider(
        "auth"
        /* _ComponentName.AUTH */
      ).getImmediate());
      return ((n) => new Ii(n))(r);
    },
    "PRIVATE"
    /* ComponentType.PRIVATE */
  ).setInstantiationMode(
    "EXPLICIT"
    /* InstantiationMode.EXPLICIT */
  )), he(_t, mt, yi(t)), he(_t, mt, "esm2017");
}
/**
 * @license
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const bi = 5 * 60;
wr("authIdTokenMaxAge");
vi(
  "Browser"
  /* ClientPlatform.BROWSER */
);
const Si = "SignupMaxUsersReachedError";
class As extends Error {
  constructor(e) {
    super(e), this.name = Si;
  }
}
class R extends Error {
  /**
   * Creates a new instance of TnError.
   * @param {string} message - The error message.
   * @param {TnErrorOptions} options - The error options.
   */
  constructor(r, n) {
    super(r);
    le(this, "code");
    le(this, "statusCode");
    le(this, "data");
    le(this, "message");
    this.message = r, this.code = n == null ? void 0 : n.code, this.statusCode = n == null ? void 0 : n.statusCode, this.data = n == null ? void 0 : n.data;
  }
}
const Ts = {
  MISSING_EMAIL_PASSWORD: "missing-email-password",
  MISSING_EMAIL: "missing-email",
  EMAIL_EXISTS_2: "auth/email-already-exists",
  // not included in AuthErrorCodes for some reason
  UID_EXISTS: "auth/uid-already-exists",
  INVITE_TOKEN_MISSING: "invite-token-missing",
  INVITE_TOKEN_EXPIRED: "invite-token-expired",
  INVITE_TOKEN_USED: "invite-token-used",
  INVITE_TOKEN_ERROR: "invite-token-error",
  CM_NOT_PREMIUM: "cm-not-premium",
  MAGIC_LINK_SEND_ERROR: "magic-link-send-error",
  ...Hn
};
function Ai(t, e) {
  const r = {};
  for (const n in t) {
    const i = t[n];
    typeof i == "object" && i !== null && !Array.isArray(i) ? r[n] = Ai(i, e) : r[n] = e(i);
  }
  return r;
}
function ws(t, e) {
  const r = {};
  for (const n in t) {
    const i = t[n];
    r[n] = e(i);
  }
  return r;
}
function Ns(t) {
  return Object.fromEntries(
    Object.entries(t).filter(([e, r]) => r !== void 0)
  );
}
var Ti = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function wi(t) {
  return t && t.__esModule && Object.prototype.hasOwnProperty.call(t, "default") ? t.default : t;
}
var Xt = { exports: {} };
(function(t, e) {
  (function(r, n) {
    t.exports = n();
  })(Ti, function() {
    var r = 1e3, n = 6e4, i = 36e5, s = "millisecond", a = "second", c = "minute", o = "hour", u = "day", _ = "week", f = "month", A = "quarter", I = "year", T = "date", O = "Invalid Date", $ = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, P = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, j = { name: "en", weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"), ordinal: function(m) {
      var h = ["th", "st", "nd", "rd"], l = m % 100;
      return "[" + m + (h[(l - 20) % 10] || h[l] || h[0]) + "]";
    } }, B = function(m, h, l) {
      var p = String(m);
      return !p || p.length >= h ? m : "" + Array(h + 1 - p.length).join(l) + m;
    }, X = { s: B, z: function(m) {
      var h = -m.utcOffset(), l = Math.abs(h), p = Math.floor(l / 60), d = l % 60;
      return (h <= 0 ? "+" : "-") + B(p, 2, "0") + ":" + B(d, 2, "0");
    }, m: function m(h, l) {
      if (h.date() < l.date())
        return -m(l, h);
      var p = 12 * (l.year() - h.year()) + (l.month() - h.month()), d = h.clone().add(p, f), g = l - d < 0, E = h.clone().add(p + (g ? -1 : 1), f);
      return +(-(p + (l - d) / (g ? d - E : E - d)) || 0);
    }, a: function(m) {
      return m < 0 ? Math.ceil(m) || 0 : Math.floor(m);
    }, p: function(m) {
      return { M: f, y: I, w: _, d: u, D: T, h: o, m: c, s: a, ms: s, Q: A }[m] || String(m || "").toLowerCase().replace(/s$/, "");
    }, u: function(m) {
      return m === void 0;
    } }, x = "en", L = {};
    L[x] = j;
    var G = "$isDayjsObject", H = function(m) {
      return m instanceof ye || !(!m || !m[G]);
    }, V = function m(h, l, p) {
      var d;
      if (!h)
        return x;
      if (typeof h == "string") {
        var g = h.toLowerCase();
        L[g] && (d = g), l && (L[g] = l, d = g);
        var E = h.split("-");
        if (!d && E.length > 1)
          return m(E[0]);
      } else {
        var S = h.name;
        L[S] = h, d = S;
      }
      return !p && d && (x = d), d || !p && x;
    }, N = function(m, h) {
      if (H(m))
        return m.clone();
      var l = typeof h == "object" ? h : {};
      return l.date = m, l.args = arguments, new ye(l);
    }, v = X;
    v.l = V, v.i = H, v.w = function(m, h) {
      return N(m, { locale: h.$L, utc: h.$u, x: h.$x, $offset: h.$offset });
    };
    var ye = function() {
      function m(l) {
        this.$L = V(l.locale, null, !0), this.parse(l), this.$x = this.$x || l.x || {}, this[G] = !0;
      }
      var h = m.prototype;
      return h.parse = function(l) {
        this.$d = function(p) {
          var d = p.date, g = p.utc;
          if (d === null)
            return /* @__PURE__ */ new Date(NaN);
          if (v.u(d))
            return /* @__PURE__ */ new Date();
          if (d instanceof Date)
            return new Date(d);
          if (typeof d == "string" && !/Z$/i.test(d)) {
            var E = d.match($);
            if (E) {
              var S = E[2] - 1 || 0, w = (E[7] || "0").substring(0, 3);
              return g ? new Date(Date.UTC(E[1], S, E[3] || 1, E[4] || 0, E[5] || 0, E[6] || 0, w)) : new Date(E[1], S, E[3] || 1, E[4] || 0, E[5] || 0, E[6] || 0, w);
            }
          }
          return new Date(d);
        }(l), this.init();
      }, h.init = function() {
        var l = this.$d;
        this.$y = l.getFullYear(), this.$M = l.getMonth(), this.$D = l.getDate(), this.$W = l.getDay(), this.$H = l.getHours(), this.$m = l.getMinutes(), this.$s = l.getSeconds(), this.$ms = l.getMilliseconds();
      }, h.$utils = function() {
        return v;
      }, h.isValid = function() {
        return this.$d.toString() !== O;
      }, h.isSame = function(l, p) {
        var d = N(l);
        return this.startOf(p) <= d && d <= this.endOf(p);
      }, h.isAfter = function(l, p) {
        return N(l) < this.startOf(p);
      }, h.isBefore = function(l, p) {
        return this.endOf(p) < N(l);
      }, h.$g = function(l, p, d) {
        return v.u(l) ? this[p] : this.set(d, l);
      }, h.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, h.valueOf = function() {
        return this.$d.getTime();
      }, h.startOf = function(l, p) {
        var d = this, g = !!v.u(p) || p, E = v.p(l), S = function(Z, M) {
          var W = v.w(d.$u ? Date.UTC(d.$y, M, Z) : new Date(d.$y, M, Z), d);
          return g ? W : W.endOf(u);
        }, w = function(Z, M) {
          return v.w(d.toDate()[Z].apply(d.toDate("s"), (g ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(M)), d);
        }, C = this.$W, D = this.$M, U = this.$D, ne = "set" + (this.$u ? "UTC" : "");
        switch (E) {
          case I:
            return g ? S(1, 0) : S(31, 11);
          case f:
            return g ? S(1, D) : S(0, D + 1);
          case _:
            var Q = this.$locale().weekStart || 0, ce = (C < Q ? C + 7 : C) - Q;
            return S(g ? U - ce : U + (6 - ce), D);
          case u:
          case T:
            return w(ne + "Hours", 0);
          case o:
            return w(ne + "Minutes", 1);
          case c:
            return w(ne + "Seconds", 2);
          case a:
            return w(ne + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, h.endOf = function(l) {
        return this.startOf(l, !1);
      }, h.$set = function(l, p) {
        var d, g = v.p(l), E = "set" + (this.$u ? "UTC" : ""), S = (d = {}, d[u] = E + "Date", d[T] = E + "Date", d[f] = E + "Month", d[I] = E + "FullYear", d[o] = E + "Hours", d[c] = E + "Minutes", d[a] = E + "Seconds", d[s] = E + "Milliseconds", d)[g], w = g === u ? this.$D + (p - this.$W) : p;
        if (g === f || g === I) {
          var C = this.clone().set(T, 1);
          C.$d[S](w), C.init(), this.$d = C.set(T, Math.min(this.$D, C.daysInMonth())).$d;
        } else
          S && this.$d[S](w);
        return this.init(), this;
      }, h.set = function(l, p) {
        return this.clone().$set(l, p);
      }, h.get = function(l) {
        return this[v.p(l)]();
      }, h.add = function(l, p) {
        var d, g = this;
        l = Number(l);
        var E = v.p(p), S = function(D) {
          var U = N(g);
          return v.w(U.date(U.date() + Math.round(D * l)), g);
        };
        if (E === f)
          return this.set(f, this.$M + l);
        if (E === I)
          return this.set(I, this.$y + l);
        if (E === u)
          return S(1);
        if (E === _)
          return S(7);
        var w = (d = {}, d[c] = n, d[o] = i, d[a] = r, d)[E] || 1, C = this.$d.getTime() + l * w;
        return v.w(C, this);
      }, h.subtract = function(l, p) {
        return this.add(-1 * l, p);
      }, h.format = function(l) {
        var p = this, d = this.$locale();
        if (!this.isValid())
          return d.invalidDate || O;
        var g = l || "YYYY-MM-DDTHH:mm:ssZ", E = v.z(this), S = this.$H, w = this.$m, C = this.$M, D = d.weekdays, U = d.months, ne = d.meridiem, Q = function(M, W, ue, ve) {
          return M && (M[W] || M(p, g)) || ue[W].slice(0, ve);
        }, ce = function(M) {
          return v.s(S % 12 || 12, M, "0");
        }, Z = ne || function(M, W, ue) {
          var ve = M < 12 ? "AM" : "PM";
          return ue ? ve.toLowerCase() : ve;
        };
        return g.replace(P, function(M, W) {
          return W || function(ue) {
            switch (ue) {
              case "YY":
                return String(p.$y).slice(-2);
              case "YYYY":
                return v.s(p.$y, 4, "0");
              case "M":
                return C + 1;
              case "MM":
                return v.s(C + 1, 2, "0");
              case "MMM":
                return Q(d.monthsShort, C, U, 3);
              case "MMMM":
                return Q(U, C);
              case "D":
                return p.$D;
              case "DD":
                return v.s(p.$D, 2, "0");
              case "d":
                return String(p.$W);
              case "dd":
                return Q(d.weekdaysMin, p.$W, D, 2);
              case "ddd":
                return Q(d.weekdaysShort, p.$W, D, 3);
              case "dddd":
                return D[p.$W];
              case "H":
                return String(S);
              case "HH":
                return v.s(S, 2, "0");
              case "h":
                return ce(1);
              case "hh":
                return ce(2);
              case "a":
                return Z(S, w, !0);
              case "A":
                return Z(S, w, !1);
              case "m":
                return String(w);
              case "mm":
                return v.s(w, 2, "0");
              case "s":
                return String(p.$s);
              case "ss":
                return v.s(p.$s, 2, "0");
              case "SSS":
                return v.s(p.$ms, 3, "0");
              case "Z":
                return E;
            }
            return null;
          }(M) || E.replace(":", "");
        });
      }, h.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, h.diff = function(l, p, d) {
        var g, E = this, S = v.p(p), w = N(l), C = (w.utcOffset() - this.utcOffset()) * n, D = this - w, U = function() {
          return v.m(E, w);
        };
        switch (S) {
          case I:
            g = U() / 12;
            break;
          case f:
            g = U();
            break;
          case A:
            g = U() / 3;
            break;
          case _:
            g = (D - C) / 6048e5;
            break;
          case u:
            g = (D - C) / 864e5;
            break;
          case o:
            g = D / i;
            break;
          case c:
            g = D / n;
            break;
          case a:
            g = D / r;
            break;
          default:
            g = D;
        }
        return d ? g : v.a(g);
      }, h.daysInMonth = function() {
        return this.endOf(f).$D;
      }, h.$locale = function() {
        return L[this.$L];
      }, h.locale = function(l, p) {
        if (!l)
          return this.$L;
        var d = this.clone(), g = V(l, p, !0);
        return g && (d.$L = g), d;
      }, h.clone = function() {
        return v.w(this.$d, this);
      }, h.toDate = function() {
        return new Date(this.valueOf());
      }, h.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, h.toISOString = function() {
        return this.$d.toISOString();
      }, h.toString = function() {
        return this.$d.toUTCString();
      }, m;
    }(), Qe = ye.prototype;
    return N.prototype = Qe, [["$ms", s], ["$s", a], ["$m", c], ["$H", o], ["$W", u], ["$M", f], ["$y", I], ["$D", T]].forEach(function(m) {
      Qe[m[1]] = function(h) {
        return this.$g(h, m[0], m[1]);
      };
    }), N.extend = function(m, h) {
      return m.$i || (m(h, ye, N), m.$i = !0), N;
    }, N.locale = V, N.isDayjs = H, N.unix = function(m) {
      return N(1e3 * m);
    }, N.en = L[x], N.Ls = L, N.p = {}, N;
  });
})(Xt);
var Ni = Xt.exports;
const Oi = /* @__PURE__ */ wi(Ni);
function Os(t) {
  return Oi(t).format("YYYY-MM-DDTHH:mm:ss");
}
var Ci = /* @__PURE__ */ ((t) => (t.Invited = "invited", t.Premium = "premium", t.Basic = "basic", t.Expired = "expired", t))(Ci || {}), Di = /* @__PURE__ */ ((t) => (t.Brand = "brand", t.Member = "member", t))(Di || {});
const Cs = (t, { length: e = 25 } = {}) => t ? t.length <= e ? t : t.slice(0, e) + "…" : "", Ds = (t) => t && t.charAt(0).toUpperCase() + t.slice(1);
function Ri(t) {
  return t.reduce((e, r) => ({ ...e, [r]: !1 }), {});
}
function Re(t, e, r) {
  if (!t || !r || !e)
    return null;
  const n = Ri(Object.values(r)), i = t[e];
  if (!i)
    return n;
  const s = { ...n };
  for (const a of i) {
    const c = r[a];
    c && (a === "other" ? s[c] = t[`${e}Other`] ?? "" : s[c] = !0);
  }
  return s;
}
const Pi = {
  none: "allergies_none",
  peanuts: "allergies_peanuts",
  treeNuts: "allergies_treenuts",
  dairy: "allergies_dairy",
  eggs: "allergies_eggs",
  wheat: "allergies_wheat",
  gluten: "allergies_gluten",
  soy: "allergies_soy",
  fish: "allergies_fish",
  shellfish: "allergies_shellfish",
  sesame: "allergies_sesame",
  other: "allergies_other"
}, Li = {
  none: "diet_none",
  keto: "diet_keto",
  lowCarb: "diet_lowcarb",
  carnivore: "diet_carnivore",
  paleo: "diet_paleo",
  vegetarian: "diet_vegetarian",
  flexitarian: "diet_flexitarian",
  pescatarian: "diet_pescatarian",
  vegan: "diet_vegan",
  other: "diet_other"
}, Mi = {
  supermarketChain: "shopping_supermarket_chain",
  supermarketHealthFood: "shopping_healthfood_supermarket",
  localGroceryStore: "shopping_independent_grocerystore",
  farmerMarket: "shopping_farmers_market",
  warehouseClub: "shopping_warehouse_club",
  onlineGrocery: "shopping_online_grocery",
  convenienceStore: "shopping_convenience_store",
  specialityFoodStore: "shopping_speciality_foodstores",
  others: "shopping_others"
}, ki = {
  ketoOrLowCarbDesserts: "purchase_lowcarb_sweets",
  ketoOrLowCarbBread: "purchase_lowcarb_bread",
  ketoOrLowCarbCereals: "purchase_lowcarb_cereals",
  ketoOrLowCarbSnacks: "purchase_lowcarb_snacks",
  ketoOrLowCarbCondimentsAndSauces: "purchase_lowcarb_condiments",
  meatSnacks: "purchase_meat",
  dairyAlternates: "purchase_dairy_alternates",
  lowOrNoSugarBeverages: "purchase_lowsugar_beverages",
  supplementsPowdersProteinShakes: "purchase_supplements",
  kidFriendlyHealthySnacks: "purchase_kid_snacks"
};
function Ui(t) {
  return Re(
    t,
    "allergiesAndIntolerances",
    Pi
  );
}
function $i(t) {
  return Re(
    t,
    "dietaryRestrictions",
    Li
  );
}
function xi(t) {
  return Re(
    t,
    "groceriesShoppingPlace",
    Mi
  );
}
function Vi(t) {
  return Re(
    t,
    "frequentlyPurchaseProducts",
    ki
  );
}
function Rs(t) {
  const e = {};
  return t.age !== void 0 && (e.age_group = t.age), t.gender !== void 0 && (e.gender = t.gender), t.selfDescription !== void 0 && (e.onboarding_self_description = t.selfDescription), t.eatingHelpHealthCondition !== void 0 && (e.way_eating_manage_health = t.eatingHelpHealthCondition), t.householdPeopleNumber !== void 0 && (e.people_household = t.householdPeopleNumber), t.householdChildrenNumber !== void 0 && (e.children_household = t.householdChildrenNumber), t.zipCode !== void 0 && (e.zip_code = t.zipCode), Object.assign(e, Ui(t) || {}), Object.assign(
    e,
    $i(t) || {}
  ), Object.assign(e, xi(t) || {}), Object.assign(
    e,
    Vi(t) || {}
  ), e;
}
const Ps = async (t, e) => {
  var i, s;
  const r = await t.getUser(e);
  if (!r)
    return null;
  const n = (s = (i = r == null ? void 0 : r.customClaims) == null ? void 0 : i.brand) == null ? void 0 : s.id;
  return n || null;
}, Pe = "GraphQL Client", gt = 0, Et = 3, Qt = "An error occurred while fetching from the API. Review 'graphQLErrors' for details.", Zt = "Response returned unexpected Content-Type:", er = "An unknown error has occurred. The API did not return a data object or any errors in its response.", Ye = {
  json: "application/json",
  multipart: "multipart/mixed"
}, It = "X-SDK-Variant", yt = "X-SDK-Version", Fi = "shopify-graphql-client", Bi = "1.0.0", tr = 1e3, Hi = [429, 503], rr = /@(defer)\b/i, vt = `\r
`, ji = /boundary="?([^=";]+)"?/i, bt = vt + vt;
function q(t, e = Pe) {
  return t.startsWith(`${e}`) ? t : `${e}: ${t}`;
}
function oe(t) {
  return t instanceof Error ? t.message : JSON.stringify(t);
}
function nr(t) {
  return t instanceof Error && t.cause ? t.cause : void 0;
}
function ir(t) {
  return t.flatMap(({ errors: e }) => e ?? []);
}
function sr({ client: t, retries: e }) {
  if (e !== void 0 && (typeof e != "number" || e < gt || e > Et))
    throw new Error(`${t}: The provided "retries" value (${e}) is invalid - it cannot be less than ${gt} or greater than ${Et}`);
}
function k(t, e) {
  return e && (typeof e != "object" || Array.isArray(e) || typeof e == "object" && Object.keys(e).length > 0) ? { [t]: e } : {};
}
function ar(t, e) {
  if (t.length === 0)
    return e;
  const n = {
    [t.pop()]: e
  };
  return t.length === 0 ? n : ar(t, n);
}
function or(t, e) {
  return Object.keys(e || {}).reduce((r, n) => (typeof e[n] == "object" || Array.isArray(e[n])) && t[n] ? (r[n] = or(t[n], e[n]), r) : (r[n] = e[n], r), Array.isArray(t) ? [...t] : { ...t });
}
function cr([t, ...e]) {
  return e.reduce(or, { ...t });
}
function Gi({ clientLogger: t, customFetchApi: e = fetch, client: r = Pe, defaultRetryWaitTime: n = tr, retriableCodes: i = Hi }) {
  const s = async (a, c, o) => {
    const u = c + 1, _ = o + 1;
    let f;
    try {
      if (f = await e(...a), t({
        type: "HTTP-Response",
        content: {
          requestParams: a,
          response: f
        }
      }), !f.ok && i.includes(f.status) && u <= _)
        throw new Error();
      return f;
    } catch (A) {
      if (u <= _) {
        const I = f == null ? void 0 : f.headers.get("Retry-After");
        return await Wi(I ? parseInt(I, 10) : n), t({
          type: "HTTP-Retry",
          content: {
            requestParams: a,
            lastResponse: f,
            retryAttempt: c,
            maxRetries: o
          }
        }), s(a, u, o);
      }
      throw new Error(q(`${o > 0 ? `Attempted maximum number of ${o} network retries. Last message - ` : ""}${oe(A)}`, r));
    }
  };
  return s;
}
async function Wi(t) {
  return new Promise((e) => setTimeout(e, t));
}
function Ki({ headers: t, url: e, customFetchApi: r = fetch, retries: n = 0, logger: i }) {
  sr({ client: Pe, retries: n });
  const s = {
    headers: t,
    url: e,
    retries: n
  }, a = Yi(i), c = Gi({
    customFetchApi: r,
    clientLogger: a,
    defaultRetryWaitTime: tr
  }), o = zi(c, s), u = qi(o), _ = rs(o);
  return {
    config: s,
    fetch: o,
    request: u,
    requestStream: _
  };
}
function Yi(t) {
  return (e) => {
    t && t(e);
  };
}
async function ur(t) {
  const { errors: e, data: r, extensions: n } = await t.json();
  return {
    ...k("data", r),
    ...k("extensions", n),
    ...e || !r ? {
      errors: {
        networkStatusCode: t.status,
        message: q(e ? Qt : er),
        ...k("graphQLErrors", e),
        response: t
      }
    } : {}
  };
}
function zi(t, { url: e, headers: r, retries: n }) {
  return async (i, s = {}) => {
    const { variables: a, headers: c, url: o, retries: u } = s, _ = JSON.stringify({
      query: i,
      variables: a
    });
    sr({ client: Pe, retries: u });
    const f = Object.entries({
      ...r,
      ...c
    }).reduce((I, [T, O]) => (I[T] = Array.isArray(O) ? O.join(", ") : O.toString(), I), {});
    return !f[It] && !f[yt] && (f[It] = Fi, f[yt] = Bi), t([
      o ?? e,
      {
        method: "POST",
        headers: f,
        body: _
      }
    ], 1, u ?? n);
  };
}
function qi(t) {
  return async (...e) => {
    if (rr.test(e[0]))
      throw new Error(q("This operation will result in a streamable response - use requestStream() instead."));
    try {
      const r = await t(...e), { status: n, statusText: i } = r, s = r.headers.get("content-type") || "";
      return r.ok ? s.includes(Ye.json) ? ur(r) : {
        errors: {
          networkStatusCode: n,
          message: q(`${Zt} ${s}`),
          response: r
        }
      } : {
        errors: {
          networkStatusCode: n,
          message: q(i),
          response: r
        }
      };
    } catch (r) {
      return {
        errors: {
          message: oe(r)
        }
      };
    }
  };
}
async function* Ji(t) {
  const e = new TextDecoder();
  if (t.body[Symbol.asyncIterator])
    for await (const r of t.body)
      yield e.decode(r);
  else {
    const r = t.body.getReader();
    let n;
    try {
      for (; !(n = await r.read()).done; )
        yield e.decode(n.value);
    } finally {
      r.cancel();
    }
  }
}
function Xi(t, e) {
  return {
    async *[Symbol.asyncIterator]() {
      try {
        let r = "";
        for await (const n of t)
          if (r += n, r.indexOf(e) > -1) {
            const i = r.lastIndexOf(e), a = r.slice(0, i).split(e).filter((c) => c.trim().length > 0).map((c) => c.slice(c.indexOf(bt) + bt.length).trim());
            a.length > 0 && (yield a), r = r.slice(i + e.length), r.trim() === "--" && (r = "");
          }
      } catch (r) {
        throw new Error(`Error occured while processing stream payload - ${oe(r)}`);
      }
    }
  };
}
function Qi(t) {
  return {
    async *[Symbol.asyncIterator]() {
      yield {
        ...await ur(t),
        hasNext: !1
      };
    }
  };
}
function Zi(t) {
  return t.map((e) => {
    try {
      return JSON.parse(e);
    } catch (r) {
      throw new Error(`Error in parsing multipart response - ${oe(r)}`);
    }
  }).map((e) => {
    const { data: r, incremental: n, hasNext: i, extensions: s, errors: a } = e;
    if (!n)
      return {
        data: r || {},
        ...k("errors", a),
        ...k("extensions", s),
        hasNext: i
      };
    const c = n.map(({ data: o, path: u, errors: _ }) => ({
      data: o && u ? ar(u, o) : {},
      ...k("errors", _)
    }));
    return {
      data: c.length === 1 ? c[0].data : cr([
        ...c.map(({ data: o }) => o)
      ]),
      ...k("errors", ir(c)),
      hasNext: i
    };
  });
}
function es(t, e) {
  if (t.length > 0)
    throw new Error(Qt, {
      cause: {
        graphQLErrors: t
      }
    });
  if (Object.keys(e).length === 0)
    throw new Error(er);
}
function ts(t, e) {
  var c;
  const r = (e ?? "").match(ji), n = `--${r ? r[1] : "-"}`;
  if (!((c = t.body) != null && c.getReader) && !t.body[Symbol.asyncIterator])
    throw new Error("API multipart response did not return an iterable body", {
      cause: t
    });
  const i = Ji(t);
  let s = {}, a;
  return {
    async *[Symbol.asyncIterator]() {
      var o;
      try {
        let u = !0;
        for await (const _ of Xi(i, n)) {
          const f = Zi(_);
          a = ((o = f.find((I) => I.extensions)) == null ? void 0 : o.extensions) ?? a;
          const A = ir(f);
          s = cr([
            s,
            ...f.map(({ data: I }) => I)
          ]), u = f.slice(-1)[0].hasNext, es(A, s), yield {
            ...k("data", s),
            ...k("extensions", a),
            hasNext: u
          };
        }
        if (u)
          throw new Error("Response stream terminated unexpectedly");
      } catch (u) {
        const _ = nr(u);
        yield {
          ...k("data", s),
          ...k("extensions", a),
          errors: {
            message: q(oe(u)),
            networkStatusCode: t.status,
            ...k("graphQLErrors", _ == null ? void 0 : _.graphQLErrors),
            response: t
          },
          hasNext: !1
        };
      }
    }
  };
}
function rs(t) {
  return async (...e) => {
    if (!rr.test(e[0]))
      throw new Error(q("This operation does not result in a streamable response - use request() instead."));
    try {
      const r = await t(...e), { statusText: n } = r;
      if (!r.ok)
        throw new Error(n, { cause: r });
      const i = r.headers.get("content-type") || "";
      switch (!0) {
        case i.includes(Ye.json):
          return Qi(r);
        case i.includes(Ye.multipart):
          return ts(r, i);
        default:
          throw new Error(`${Zt} ${i}`, { cause: r });
      }
    } catch (r) {
      return {
        async *[Symbol.asyncIterator]() {
          const n = nr(r);
          yield {
            errors: {
              message: q(oe(r)),
              ...k("networkStatusCode", n == null ? void 0 : n.status),
              ...k("response", n)
            },
            hasNext: !1
          };
        }
      };
    }
  };
}
function ns({ client: t, storeDomain: e }) {
  try {
    if (!e || typeof e != "string")
      throw new Error();
    const r = e.trim(), n = r.match(/^https?:/) ? r : `https://${r}`, i = new URL(n);
    return i.protocol = "https", i.origin;
  } catch {
    throw new Error(`${t}: a valid store domain ("${e}") must be provided`);
  }
}
function lr({ client: t, currentSupportedApiVersions: e, apiVersion: r, logger: n }) {
  const i = `${t}: the provided apiVersion ("${r}")`, s = `Currently supported API versions: ${e.join(", ")}`;
  if (!r || typeof r != "string")
    throw new Error(`${i} is invalid. ${s}`);
  const a = r.trim();
  e.includes(a) || (n ? n({
    type: "Unsupported_Api_Version",
    content: {
      apiVersion: r,
      supportedApiVersions: e
    }
  }) : console.warn(`${i} is likely deprecated or not supported. ${s}`));
}
function Oe(t) {
  const e = t * 3 - 2;
  return e === 10 ? e : `0${e}`;
}
function Fe(t, e, r) {
  const n = e - r;
  return n <= 0 ? `${t - 1}-${Oe(n + 4)}` : `${t}-${Oe(n)}`;
}
function is() {
  const t = /* @__PURE__ */ new Date(), e = t.getUTCMonth(), r = t.getUTCFullYear(), n = Math.floor(e / 3 + 1);
  return {
    year: r,
    quarter: n,
    version: `${r}-${Oe(n)}`
  };
}
function ss() {
  const { year: t, quarter: e, version: r } = is(), n = e === 4 ? `${t + 1}-01` : `${t}-${Oe(e + 1)}`;
  return [
    Fe(t, e, 3),
    Fe(t, e, 2),
    Fe(t, e, 1),
    r,
    n,
    "unstable"
  ];
}
function as(t) {
  return (e) => ({ ...e ?? {}, ...t.headers });
}
function os({ getHeaders: t, getApiUrl: e }) {
  return (r, n) => {
    const i = [r];
    if (n && Object.keys(n).length > 0) {
      const { variables: s, apiVersion: a, headers: c, retries: o } = n;
      i.push({
        ...s ? { variables: s } : {},
        ...c ? { headers: t(c) } : {},
        ...a ? { url: e(a) } : {},
        ...o ? { retries: o } : {}
      });
    }
    return i;
  };
}
const St = "application/json", cs = "1.0.0", us = "X-Shopify-Access-Token", ie = "Admin API Client";
function ls(t) {
  if (!t)
    throw new Error(`${ie}: an access token must be provided`);
}
function ds() {
  if (typeof window < "u")
    throw new Error(`${ie}: this client should not be used in the browser`);
}
function hs({ storeDomain: t, apiVersion: e, accessToken: r, userAgentPrefix: n, retries: i = 0, customFetchApi: s, logger: a }) {
  const c = ss(), o = ns({
    client: ie,
    storeDomain: t
  }), u = {
    client: ie,
    currentSupportedApiVersions: c,
    logger: a
  };
  ds(), lr({
    client: ie,
    currentSupportedApiVersions: c,
    apiVersion: e,
    logger: a
  }), ls(r);
  const _ = fs(o, e, u), f = {
    storeDomain: o,
    apiVersion: e,
    accessToken: r,
    headers: {
      "Content-Type": St,
      Accept: St,
      [us]: r,
      "User-Agent": `${n ? `${n} | ` : ""}${ie} v${cs}`
    },
    apiUrl: _(),
    userAgentPrefix: n
  }, A = Ki({
    headers: f.headers,
    url: f.apiUrl,
    retries: i,
    customFetchApi: s,
    logger: a
  }), I = as(f), T = ps(f, _), O = os({
    getHeaders: I,
    getApiUrl: T
  });
  return Object.freeze({
    config: f,
    getHeaders: I,
    getApiUrl: T,
    fetch: (...P) => A.fetch(...O(...P)),
    request: (...P) => A.request(...O(...P))
  });
}
function fs(t, e, r) {
  return (n) => {
    n && lr({
      ...r,
      apiVersion: n
    });
    const i = (n ?? e).trim();
    return `${t}/admin/api/${i}/graphql.json`;
  };
}
function ps(t, e) {
  return (r) => r ? e(r) : t.apiUrl;
}
var At;
(function(t) {
  t.Get = "GET", t.Post = "POST", t.Put = "PUT", t.Delete = "DELETE";
})(At || (At = {}));
const _s = "2024-10", ms = ["read_products", "write_orders"], dr = ({
  storeId: t,
  accessToken: e
}) => hs({
  storeDomain: t,
  accessToken: e,
  apiVersion: _s,
  retries: 3
}), hr = async (t, e) => {
  var i;
  const r = (i = e == null ? void 0 : e.shopify) == null ? void 0 : i.storeId;
  if (!r)
    return null;
  const n = await t.collection("shopify_sessions").doc(`offline_${r}`).get();
  return n.exists ? J(n) : null;
}, fr = async (t, e) => {
  var c;
  const r = await t.collection("brandStatus").doc(e).get();
  if (!r.exists)
    return null;
  const n = J(r);
  if (!(n != null && n.shopify))
    return null;
  const i = (c = n == null ? void 0 : n.shopify) == null ? void 0 : c.storeId;
  if (!i)
    return null;
  const s = await hr(t, n), a = s == null ? void 0 : s.accessToken;
  return a ? { accessToken: a, storeId: i, shopifyBrandStatus: n.shopify } : null;
}, Ls = async (t, e) => {
  const r = await t.collection("brandStatus").doc(e).get();
  if (!r.exists)
    return !1;
  const n = J(r), i = await hr(t, n);
  if (!i)
    return !0;
  const s = i == null ? void 0 : i.scope;
  if (!s)
    return !0;
  const a = s.split(",");
  return ms.some((c) => !a.includes(c));
}, Ms = async (t, e) => {
  const { variant: r, product: n, brandId: i } = e, s = await t.collection("brands").doc(i).get(), a = J(s), c = {
    shopifyVariantId: r.id,
    title: `${n.title} ${!n.hasOnlyDefaultVariant && r.title ? ` - ${r.title}` : ""}`,
    brand: {
      id: a.id,
      name: a.name,
      logoImage: a.logoImage
    },
    createdAtTimeMs: Date.now(),
    updatedAtTimeMs: Date.now(),
    sku: r.sku || "",
    descriptionHTML: n.descriptionHtml,
    retailPrice: r.price
  }, o = n.images;
  return o != null && o.length && (c.productImage = o[0], c.images = o), await t.collection("products").add(c), c;
}, ks = async (t, e) => {
  const { brandId: r } = e;
  await t.collection("brandStatus").doc(r).set(
    {
      shopify: null,
      updatedAtTimeMs: Date.now()
    },
    { merge: !0 }
  );
}, Us = async (t, e) => {
  var a;
  const { brandId: r } = e, n = await t.collection("brandStatus").doc(r).get();
  if (!n.exists)
    return !1;
  const i = J(n), s = (a = i == null ? void 0 : i.shopify) == null ? void 0 : a.storeId;
  return s ? (await t.collection("shopify_sessions").doc(`offline_${s}`).delete(), !0) : !1;
}, Tt = "tn_order_id", wt = "is_tn_order", $s = async ({
  db: t,
  orderId: e
}) => {
  var X, x, L, G;
  if (!e)
    throw new R("Order ID is required", { statusCode: 400 });
  const r = await t.collection("orders").doc(e).get();
  if (!r.exists)
    throw new R("Order not found", {
      statusCode: 404,
      data: { orderId: e }
    });
  const n = J(r), i = (X = n == null ? void 0 : n.product) == null ? void 0 : X.brandId;
  if (!i)
    throw new R("Order does not have a brand ID", {
      statusCode: 404,
      data: { orderId: e }
    });
  const s = await fr(t, i);
  if (!(s != null && s.storeId) || !(s != null && s.accessToken))
    throw new R("Shopify access not found", {
      statusCode: 401,
      data: { orderId: e, brandId: i }
    });
  if (!((x = s.shopifyBrandStatus) != null && x.ordersSyncEnabled))
    throw new R("Orders sync is disabled", {
      statusCode: 400,
      data: { orderId: e, brandId: i }
    });
  const a = dr({
    storeId: s.storeId,
    accessToken: s.accessToken
  }), c = n == null ? void 0 : n.deliveryAddress;
  if (!c)
    throw new R("Order does not have a delivery address", {
      statusCode: 400,
      data: { orderId: e, brandId: i }
    });
  const o = (L = n == null ? void 0 : n.product) == null ? void 0 : L.id;
  if (!o)
    throw new R("Order does not have a product", {
      statusCode: 400,
      data: { orderId: e, brandId: i }
    });
  const u = await t.collection("products").doc(o).get();
  if (!u.exists)
    throw new R("Product not found", {
      statusCode: 404,
      data: { orderId: e, brandId: i, tnProductId: o }
    });
  const _ = J(u), f = {
    quantity: 1,
    requiresShipping: !0
  }, A = _ == null ? void 0 : _.shopifyVariantId;
  let I = !A;
  if (A) {
    const { data: H, errors: V } = await a.request(
      `#graphql
      query CheckProductVariant($id: ID!) {
        productVariant(id: $id) {
          id
        }
      }`,
      {
        variables: {
          id: A
        }
      }
    );
    !V && (H != null && H.productVariant) ? f.variantId = A : I = !0;
  }
  I && (f.title = _ == null ? void 0 : _.title, f.sku = _ == null ? void 0 : _.sku, f.priceSet = {
    shopMoney: {
      amount: "0",
      currencyCode: "USD"
    }
  });
  const T = `#graphql
  mutation OrderCreate($order: OrderCreateOrderInput!, $options: OrderCreateOptionsInput) {
    orderCreate(order: $order, options: $options) {
      userErrors {
        field
        message
      }
      order {
        id
        name
        totalTaxSet {
          shopMoney {
            amount
            currencyCode
          }
        }
        lineItems(first: 5) {
          nodes {
            variant {
              id
            }
            id
            title
            quantity
            taxLines {
              title
              rate
              priceSet {
                shopMoney {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
      }
    }
  }`, { data: O, errors: $ } = await a.request(T, {
    variables: {
      order: {
        lineItems: [f],
        tags: ["taste-network"],
        note: "Order generated by Taste Network",
        customAttributes: [
          {
            key: Tt,
            value: e
          },
          {
            key: wt,
            value: "TRUE"
          }
        ],
        metafields: [
          {
            key: Tt,
            value: e,
            type: "single_line_text_field",
            namespace: "taste-network"
          },
          {
            key: wt,
            value: "TRUE",
            type: "single_line_text_field",
            namespace: "taste-network"
          }
        ],
        shippingAddress: {
          address1: c.addressLine1,
          address2: c.addressLine2,
          city: c.city,
          countryCode: c.country,
          provinceCode: c.state,
          zip: c.postalCode,
          firstName: c.firstName,
          lastName: c.lastName
        }
      }
    }
  });
  if ($)
    throw new R($.message || "Failed to create order", {
      statusCode: 500,
      data: { orderId: e, brandId: i, tnProductId: o, errors: $, response: O }
    });
  const P = (G = O == null ? void 0 : O.orderCreate) == null ? void 0 : G.order, j = P == null ? void 0 : P.id;
  if (!j)
    throw new R("Shopify order not created", {
      statusCode: 500,
      data: { orderId: e, brandId: i, tnProductId: o, errors: $, response: O }
    });
  const B = {
    shopifyOrder: {
      id: j,
      name: P == null ? void 0 : P.name
    }
  };
  return await t.collection("orders").doc(e).update(B), { shopifyOrderId: j };
}, xs = async ({
  shopifyOrderId: t,
  tnOrderId: e,
  db: r
}) => {
  var I, T;
  const n = await r.collection("orders").doc(e).get();
  if (!n.exists)
    throw new R("Order not found", {
      statusCode: 404,
      data: { tnOrderId: e, shopifyOrderId: t }
    });
  const i = J(n), s = (I = i == null ? void 0 : i.product) == null ? void 0 : I.brandId;
  if (!s)
    throw new R("Order does not have a brand ID", {
      statusCode: 404,
      data: { tnOrderId: e, shopifyOrderId: t }
    });
  const a = await fr(r, s);
  if (!(a != null && a.storeId) || !(a != null && a.accessToken))
    throw new R("Shopify access not found", {
      statusCode: 401,
      data: { tnOrderId: e, brandId: s, shopifyOrderId: t }
    });
  if (!((T = a.shopifyBrandStatus) != null && T.ordersSyncEnabled))
    throw new R("Orders sync is disabled", {
      statusCode: 400,
      data: { tnOrderId: e, brandId: s, shopifyOrderId: t }
    });
  const c = dr({
    storeId: a.storeId,
    accessToken: a.accessToken
  }), { data: o, errors: u } = await c.request(
    `#graphql
      query SyncOrderGet($id: ID!) {
        order(id: $id) {
          name,
          displayFulfillmentStatus
        }
      }`,
    {
      variables: {
        id: t
      }
    }
  );
  if (u)
    throw new R(u.message || "Failed to sync order", {
      statusCode: 500,
      data: { tnOrderId: e, brandId: s, errors: u, response: o }
    });
  if (!(o != null && o.order))
    throw new R("Failed to sync order", {
      statusCode: 500,
      data: { tnOrderId: e, brandId: s, errors: u, response: o }
    });
  const A = {
    status: o.order.displayFulfillmentStatus === "FULFILLED" ? Be.Fulfilled : (
      // any status other than fulfilled is unfulfilled: https://shopify.dev/docs/api/admin-graphql/2024-10/enums/OrderDisplayFulfillmentStatus
      Be.Unfulfilled
    ),
    shopifyOrder: {
      id: t,
      name: o.order.name
    }
  };
  await r.collection("orders").doc(e).update(A);
};
export {
  Is as BrandBasicPlans,
  Es as BrandFullPlans,
  mr as BrandSubscriptionPlan,
  bs as CYCLE_MAX_TOKENS,
  wt as IS_TN_ORDER_ATTRIBUTE,
  Si as MAX_USERS_REACHED_ERROR,
  Ci as MemberStatus,
  Be as OrderStatus,
  gr as ProductStatusType,
  _s as SHOPIFY_API_VERSION,
  ms as SHOPIFY_APP_SCOPES,
  As as SignupMaxUsersReachedError,
  Ts as TN_ERROR_CODES,
  Tt as TN_ORDER_ID_ATTRIBUTE,
  R as TnError,
  Ss as US_STATES,
  Di as UserType,
  Ds as capitalizeFirstLetter,
  Os as convertTimestampToDate,
  ks as disconnectBrandFromShopifyStore,
  Ls as doesBrandNeedToUpdateShopifyApp,
  $s as exportOrderToShopify,
  Us as forceShopifyAppUpdate,
  Ps as getBrandIdForUser,
  fr as getShopifyAccessForBrand,
  dr as getShopifyClient,
  hr as getShopifySessionForBrand,
  ys as loginProviders,
  Ui as mapAllergiesToAnalytics,
  $i as mapDietaryRestrictionsToAnalytics,
  Vi as mapFrequentlyPurchasedProductsToAnalytics,
  xi as mapGroceryShoppingToAnalytics,
  Rs as mapMemberOnboardingDataToAnalytics,
  Ns as removeUndefinedValues,
  Ms as saveShopifyProduct,
  J as snapshotToTyped,
  xs as syncShopifyOrderToTn,
  ws as transformValues,
  Ai as transformValuesDeep,
  Cs as truncate,
  vs as wait
};
