import { useQuery } from '@tanstack/vue-query';
import { fetchCurrentUser } from '../api/requests';
import { UserType } from '@tn/shared';

export function useCurrentUserQuery() {
  return useQuery({
    queryKey: ['me'],
    queryFn: () => fetchCurrentUser(),
    placeholderData: {
      uid: null,
      email: null,
      isEmailVerified: false,
      hasCompletedOnboarding: false,
      testClockTimeMs: null,
      isCmUser: false,
      isPremium: false,
      userType: UserType.Member,
      isBrand: false,
      memberUser: null,
      brandUser: null,
    },
    staleTime: 1000 * 60 * 60 * 1, // 1 hour
  });
}
