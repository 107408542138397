import { useQuery } from '@tanstack/vue-query';
import { fetchCycles } from '../api/requests';

export function useCyclesQuery() {
  return useQuery({
    queryKey: ['cycles'],
    queryFn: () => fetchCycles(),
    placeholderData: [],
    initialData: [],
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
  });
}
