import {
  BrandSubscriptionPlan,
  type Cycle,
  type FastSpringSubscription,
} from '@tn/shared';

export const ANALYTICS_PLAN_EVENT_ID_MAP: {
  [key in BrandSubscriptionPlan | 'Cancel']: string;
} = {
  [BrandSubscriptionPlan.Full_3M]: 'full_quarterly ',
  [BrandSubscriptionPlan.Full_1M]: 'full_monthly',
  [BrandSubscriptionPlan.Basic_1M]: 'basecamp',
  Cancel: 'cancel_subscription',
};
export const getCurrentSubscription = async (forceRefresh?: boolean) => {
  const subscription = await $fetch('/api/fastspring/current-subscription', {
    query: { forceRefresh },
  });
  return subscription as FastSpringSubscription | null;
};

export const isSubscriptionActiveOrInGracePeriod = ({
  subscription,
  currentCycle,
}: {
  subscription: FastSpringSubscription;
  currentCycle: Cycle;
}) => {
  if (subscription.active === true) {
    return true;
  }

  const endMs = subscription.deactivationDate;
  if (!endMs) {
    return false;
  }

  if (!currentCycle) {
    return false;
  }

  if (endMs > currentCycle.startDate && endMs < currentCycle.endDate) {
    // Grace period if the subscription ended within the current cycle
    return true;
  }

  return false;
};
